body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

hr {
    border: none;
    border-top: 0.2rem solid var(--light-gray);
    margin: 4rem auto;
    max-width: var(--page-width);
}

@media (min-width: 800px) {

hr {
        border-top-width: 0.4rem
}
    }

.App {
  text-align: center;
}

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.page-wrapper {
    background: white;
    padding: calc(var(--gutter) * 2) var(--gutter);
}

    .page-wrapper--full-width {
        margin-left: calc(var(--gutter) * -1);
        margin-right: calc(var(--gutter) * -1);
    }

    .page-wrapper--full-height {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    @media (min-width: 800px) {

    .page-wrapper--full-height {
            height: auto
    }
        }

    .page-wrapper--player-is-visible {
        padding-bottom: calc(var(--gutter) + 10rem);
    }

.flags-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
}
  
  
  .flags-container .flag {
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4rem;
      height: 4rem;
      position: relative;
  }
  
  
  .flags-container .flag:hover {
          opacity: .7;
      }
  
  
  .flags-container .selected {
      border: 2px solid white;
  }
.login {
    height: calc(1vh * 100 - var(--header-height));
    height: calc(1vh * 100 - var(--header-height));
    height: calc(var(--vh, 1vh) * 100 - var(--header-height));
}

    @media (min-width: 800px) {.login {
        max-width: var(--narrow-page-width);

        margin: 0 auto
}
    }

    .login .big,
    .login .smaller {
        margin-bottom: 2rem;
    }

    .login input {
        margin-top: 4.4rem;
    }

    .login .error {
        border-bottom: 1px solid var(--red);
        color: var(--red);
        margin-bottom: 1.2rem;
    }

    .login h3 {
        margin-bottom: 1.2rem;
    }

    .login .smaller--error {
            color: var(--red);
        }

    .login span {
        text-decoration: underline;
        cursor: pointer;
    }

    .login__link-sent span {
            color: var(--orange);
        }

    @media (min-width: 800px) {

    .login__link-sent p:last-child {
                margin-bottom: 6.4rem
        }
            }

    @media (min-width: 800px) {

    .login__cta {
            display: flex;
            justify-content: space-between;

            margin-top: 2rem
    }

            .login__cta p {
                max-width: 27.6rem;
            }

            .login__cta button {
                height: 5.6rem;
            }
        }

    .login .action-flags {
        display: flex;
    }

    .login .action-flags p {
            width: 70%;
        }

    @media (min-width: 800px) {

    .login .action-flags p {
                width: 90%
        }
            }

    .login .action-flags .flags-box {
            width: 30%;
        }

    .login .action-flags .flags-box .flag {
                width: 3rem;
                height: 3rem;
            }

    .login .action-flags .flags-box .selected {
                border: 2px solid black;
            }

    @media (min-width: 800px) {

    .login .action-flags .flags-box {
                width: 20%
        }
            }

.update-profile {
    height: calc(1vh * 100 - var(--header-height));
    height: calc(1vh * 100 - var(--header-height));
    height: calc(var(--vh, 1vh) * 100 - var(--header-height));
}

    @media (min-width: 800px) {.update-profile {
        max-width: var(--narrow-page-width);
        margin: 0 auto
}
    }

    .update-profile .big {
        margin-bottom: 4.4rem;
    }

    .update-profile input {
        display: block;
        margin-bottom: 2rem;
        width: 100%;
        height: 4.8rem;
        color: black;
    }

    .update-profile button {
        margin-top: 2rem;
    }

    @media (min-width: 800px) {

    .update-profile button {
            margin-top: 0
    }
        }

    @media (min-width: 800px) {

    .update-profile__cta {
            display: flex;
            justify-content: space-between;

            margin-top: 2rem
    }

            .update-profile__cta p {
                max-width: 27.6rem;
            }

            .update-profile__cta button {
                height: 5.6rem;
            }
        }

    .update-profile .custom-select {
        width: 100%;
        padding: 2rem 0 1.4rem 0;
        border: none;
        border-bottom: 1px solid var(--mid-gray);
        font-size: 1.7rem;
        background: white;
    }

    .update-profile .custom-select:focus {
        outline: none;
    }

    .update-profile--modal {
        height: auto;
    }

    .update-profile--modal .page-wrapper {
            padding-top: 0;
        }

    .update-profile--modal .update-profile__cta {
            flex-direction: row;
        }

    .update-profile--modal .update-profile__cta .button-secondary {
                margin-right: 1.5rem;
            }

    @media (min-width: 800px) {

    .update-profile--modal .update-profile__cta {
                display: flex;
                justify-content: space-between
        }

                .update-profile--modal .update-profile__cta button {
                    width: 48%;
                }
            }

    .update-profile span {
        text-decoration: underline;
        cursor: pointer;
    }

nav {
    width: 100%;
    height: calc(100% - var(--header-height));
    position: absolute;
    top: var(--header-height);
    left: 0;
    background-color: transparent;
    overflow-y: scroll;
}

    nav::-webkit-scrollbar {
        display: none;
    }

    nav {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

    /* Firefox */

    @media (min-width: 800px) {nav {
        /* padding: 0 var(--narrow-page-padding); */
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center
}
    }

    /* IE and Edge */

    nav ul + ul {
        margin-top: 2.8rem;
    }

    /* Hide scrollbar for IE, Edge and Firefox */

    nav ul {
        border-top: 2px solid rgba(255, 255, 255, 0.2);
        padding-top: 2.8rem;
    }

    @media (min-width: 800px) {

    nav ul {
            padding-top: 0;
            border-top: none;
            border-left: 2px solid rgba(255, 255, 255, 0.2);
            padding-left: 4.8rem
    }
        }

    nav ul:first-child {
        padding-top: 2.8rem;
    }

    @media (min-width: 800px) {

    nav ul:first-child {
            padding-top: 0
    }
            
        }

    @media (min-width: 1024px) {
            
        }

    @media (min-width: 800px) {

    nav ul + ul {
            margin-top: 0
    }
        }

    nav ul li {
        padding: 1.4rem 0;
        font-size: var(--p-larger);
        line-height: 2.7rem;
        color: var(--white);
        text-decoration: none;
        cursor: pointer;
    }

    @media (min-width: 800px) {

    nav ul li {
            font-size: 2.8rem;
            line-height: 3.4rem
    }
        }

    nav ul li a {
            color: var(--white);
            text-decoration: none;
        }

    nav ul li.main {
            font-size: var(--h3);
            line-height: 3.4rem;
        }

    @media (min-width: 800px) {

    nav ul li.main {
                margin-bottom: 2.2rem;
                font-size: var(--h2);
                line-height: 4.2rem
        }
            }

    nav ul li.main:first-child {
                padding-top: 0;
            }

    nav hr {
        border-top: 2px solid rgba(255, 255, 255, 0.2);
        margin: 2.8rem 0;
    }

    @media (min-width: 800px) {

    nav hr {
            display: none
    }
        }

    nav button {
        border: none;
        background: none;
        padding: 0;
        font-size: var(--p-larger);
        line-height: 2.7rem;
        color: var(--white);
        
        font-family: var(--font-family);
    }

    @media (min-width: 800px) {

    nav button {
            font-size: 2.8rem;
            line-height: 3.4rem;
            margin-top: 2.2rem
    }
        }

    nav .page-wrapper {
        background: transparent;
        padding-top: 0;
    }

    @media (min-width: 800px) {

    nav .page-wrapper {
            padding-top: 12rem;
            display: flex;
            justify-content: flex-start;
            width: 100%
    }
        }

    @media (min-width: 800px) {
        nav .main-list {
            width: 40%;
        }
        nav .second-list {
            width: 35%;
        }
        nav .third-list {
            width: 25%;
        }
    }

    @media screen and (min-width: 1200px) {
        nav .page-wrapper {
            width: 1100px;
        }
    }

    nav ul .sign-out-container-mobile {
        margin-top: -1rem;
        display: flex;
    }

    @media screen and (min-width: 800px) {

    nav ul .sign-out-container-mobile {
            display: none
    }
        }

    nav ul .sign-out-container-mobile .btn-container {
            width: 40%;
            display: flex;
        }

    nav ul .sign-out-container-mobile .flags-box-mobile {
            width: 60%;
            padding: 10px 0 10px 15%;
            border-left: 2px solid rgba(255, 255, 255, 0.5);
        }

    nav .third-list {
        display: none;
    }

    @media screen and (min-width: 800px) {

    nav .third-list {
            display: flex;
            align-items: flex-end;
            padding-bottom: 3rem
    }
        }

    nav .third-list div {
            width: 100%;
        }

    nav .third-list div .sign-out-container-desktop {
                width: 100%;
            }

    nav .third-list div .btn-container {
                width: 100%;
                display: flex;
                justify-content: flex-end;
            }

    nav .third-list div .flags-box-desktop {
                border-bottom: 2px solid rgba(255, 255, 255, 0.5);
                padding-bottom: 2rem;
            }

header {
    display: flex;
    justify-content: center;
}

    header .header {
        width: calc(100% - (var(--header-margin) * 2));
        z-index: 1000;
        position: fixed;

        height: var(--header-height);
        padding: 2rem;
        border-radius: 33px;
        margin-top: var(--header-margin);
        overflow: hidden;
        -webkit-clip-path: border-box;
                clip-path: border-box;

        transition: border-radius 0.3s 0.4s ease-in-out,
            margin-top 0.3s 0.4s ease-in-out, width 0.3s 0.4s ease-in-out,
            height 0.3s ease-in-out;
    }

    @media (min-width: 1440px) {

    header .header {
            width: 120rem
    }
        }

    header .header.expanded {
            width: 100%;
            height: 100%;
            margin-top: 0;
            border-radius: 0;

            transition: border-radius 0.3s ease-in-out,
                margin-top 0.3s ease-in-out, width 0.3s ease-in-out,
                height 0.3s 0.4s ease-in-out;
        }

    header .header.expanded .header__heading__links {
                    display: none;
                }

    header .header.expanded .header__heading {
                transition: height 0.3s 0.4s ease-in-out;
}

    @media (min-width: 800px) {

    header .header.expanded .header__heading {
                    height: 10rem
            }
                }

    header .header .header__background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }

    header .header .header__background svg {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

    header .header__heading {
            position: absolute;
            width: 100%;
            height: calc(var(--header-height) + 0.2rem);
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            transition: height 0.3s ease-in-out;
        }

    @media (min-width: 800px) {

    header .header__heading {
                border-bottom: 2px solid rgba(255, 255, 255, 0.2);
                left: 50%;
                -webkit-transform: translateX(-50%);
                        transform: translateX(-50%)
        }
            }

    @media (min-width: 1024px) {

    header .header__heading {
                width: calc(100vw - 24rem)
        }
            }

    @media (min-width: 1440px) {

    header .header__heading {
                width: 120rem
        }
            }

    header .header__heading h1 {
                line-height: 0;
            }

    header .header__heading h1 a {
                    padding: 4rem 4rem 1.5rem 2rem;
                }

    @media (min-width: 1024px) {

    header .header__heading h1 a {
                        padding-left: 4rem
                }
                    }

    header .header__heading__open-button {
                padding: 4rem;
                padding-right: 2rem;
                cursor: pointer;
            }

    @media (min-width: 1024px) {

    header .header__heading__open-button {
                    padding-right: 4rem
            }
                }

    header .header__heading__links {
                display: none;
            }

    @media (min-width: 1024px) {

    header .header__heading__links {
                    display: block;

                    position: absolute;
                    right: 15rem
            }
                }

    header .header__heading__links a {
                    font-size: var(--p-larger);
                    color: var(--white);
                }

    header .header__heading__links a:not(:last-child) {
                    margin-right: 5rem;
                }

.fake-header {
    height: calc(var(--header-height) + var(--header-margin));
}

.bg-delivering .shape-1 {
        fill: #0f3f85;
    }

.bg-delivering .shape-2 {
        fill: #1f4e8e;
    }

.bg-delivering .shape-3 {
        fill: #316097;
    }

.bg-delivering .shape-4 {
        fill: #416f9f;
    }

.bg-delivering .shape-5 {
        fill: #628fb0;
    }

.bg-delivering .shape-6 {
        fill: #75a2ba;
    }

.bg-delivering .shape-7 {
        fill: #8db9c7;
    }

.bg-delivering .shape-8 {
        fill: #a3ced2;
    }

.bg-influencing .shape-1 {
        fill: #f53b3b;
    }

.bg-influencing .shape-2 {
        fill: #f74c4d;
    }

.bg-influencing .shape-3 {
        fill: #f85959;
    }

.bg-influencing .shape-4 {
        fill: #f96667;
    }

.bg-influencing .shape-5 {
        fill: #fa7071;
    }

.bg-influencing .shape-6 {
        fill: #fc8586;
    }

.bg-influencing .shape-7 {
        fill: #fe9596;
    }

.bg-influencing .shape-8 {
        fill: #ffa5a6;
    }

.bg-examining .shape-1 {
        fill: #229481;
    }

.bg-examining .shape-2 {
        fill: #389980;
    }

.bg-examining .shape-3 {
        fill: #529e7f;
    }

.bg-examining .shape-4 {
        fill: #6ca47e;
    }

.bg-examining .shape-5 {
        fill: #96ad7c;
    }

.bg-examining .shape-6 {
        fill: #b2b37b;
    }

.bg-examining .shape-7 {
        fill: #dfbc79;
    }

.bg-examining .shape-8 {
        fill: #fec378;
    }

.bg-connecting .shape-1 {
        fill: #fea5a6;
    }

.bg-connecting .shape-2 {
        fill: #e29aa3;
    }

.bg-connecting .shape-3 {
        fill: #c690a0;
    }

.bg-connecting .shape-4 {
        fill: #ae879e;
    }

.bg-connecting .shape-5 {
        fill: #907b9b;
    }

.bg-connecting .shape-6 {
        fill: #727098;
    }

.bg-connecting .shape-7 {
        fill: #526495;
    }

.bg-connecting .shape-8 {
        fill: #295591;
    }


.course-row {
  display: flex;
  padding: 0 2%;
  border-bottom: 3px solid var(--light-gray);
}

  .course-row hr {
    color: blue;
    width: 3px;
    background: var(--light-gray);
    margin: 2.8rem 1.5rem !important;
  }

  .course-row .left-column {
    width: 18%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .course-row .left-column img {
      width: 50px;
      height: 50px;
    }

  .course-row .middle-column {
    width: 40%;
    padding: 2% 0%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (min-width:500px) {

  .course-row .middle-column {
      width: 60%;
      padding: 2% 9%
  }
    }

  .course-row .middle-column p {
      text-align: center;
    }

  .course-row .middle-column span {
      cursor: pointer;
      color: red;
      font-size: 1.5rem;
      -webkit-animation-name: c-style-pulse;
              animation-name: c-style-pulse;
      -webkit-animation-duration: 4s;
              animation-duration: 4s;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
    }

  @media screen and (min-width: 500px) {

  .course-row .middle-column span {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        padding-top: 1rem;
        font-size: 1.7rem
    }
      }

  .course-row .right-column {
    width: 18%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .course-row .right-column img {
      height: 2.5rem;
      width: 2.5rem;
    }

.goBtn {
  border-radius: 40px;
  padding: 4px 10px 3px;
  color: white;
  cursor: pointer;
  border: none;
  background-color: var(--petrol);
}

.goBtn:hover {
    opacity: .6;
  }

.no-border-bottom {
  border-bottom: none;
}

.c-style-row {
  flex-direction: column;
  padding-top: 2rem;
}

@media screen and (min-width: 500px) {

.c-style-row {
    padding-top: 3rem
}
  }

@-webkit-keyframes c-style-pulse {
  10% {
    opacity: .2;
  }

  20% {
    opacity: .4;
  }

  30% {
    opacity: .6;
  }

  40% {
    opacity: .8;
  }

  50% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  70% {
    opacity: .8;
  }

  80% {
    opacity: .6;
  }

  90% {
    opacity: .4;
  }

  100% {
    opacity: 2;
  }

}

@keyframes c-style-pulse {
  10% {
    opacity: .2;
  }

  20% {
    opacity: .4;
  }

  30% {
    opacity: .6;
  }

  40% {
    opacity: .8;
  }

  50% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  70% {
    opacity: .8;
  }

  80% {
    opacity: .6;
  }

  90% {
    opacity: .4;
  }

  100% {
    opacity: 2;
  }

}
.result_quadrant__header {
      height: 16rem;
      overflow: hidden;
      position: relative;
      top: 0; 
      left: 0;
      margin-bottom: 2.8rem;
  }
      
      .result_quadrant__header svg {
          width: 130%;
      }
      
      @media (min-width: 1024px) {
      
      .result_quadrant__header svg {
              -webkit-transform: scale(1.8) translateY(+23%);
                      transform: scale(1.8) translateY(+23%)
      }
          }
  .result_quadrant__copy {
      position: absolute;
      top: 0;
      left: 0;
      color: white;
      padding: 6.5rem 2rem 0;
  }
  .result_quadrant__copy h6.smaller {
          text-transform: uppercase;
          font-size: 1.5rem;
          padding-top: 1rem;
      }
  .result_quadrant__copy h1 {
          text-transform: capitalize;
      }
.pos-absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
}
.dashboard {
    margin: 0 auto;
    padding-bottom: 1rem;
}

    @media (min-width: 1024px) {.dashboard {
        width: calc(100% - 44rem)
}
    }

    @media (min-width: 1440px) {.dashboard {
        max-width: var(--wide-page-width)
}
    }

    .dashboard .page-wrapper {
        padding-bottom: 0;
    }

    .dashboard .subtitle {
        margin-bottom: 1.2rem;
    }

    .dashboard .level-banner-section {
        min-height: 0px;
        margin: 4rem auto 0;

    }

    .dashboard .level-banner-section h1 {
            font-size: 2.2rem;
            margin-bottom: 1.5rem;
            font-weight: 600;
        }

    .dashboard .level-banner-section h3 {
            color: black;
            font-size: 1.8rem;
            margin-top: 1rem;
            text-align: center;
        }

    .dashboard .level-banner-section .user-level-container {
            height: 12rem;
            position: relative;
            overflow: hidden;
            width: 100vw;
            -webkit-transform: translateX(calc(-1 * var(--gutter)));
                    transform: translateX(calc(-1 * var(--gutter)));
            padding: 20px 0;
            min-height: 0px;
            
        }

    @media screen and (min-width: 600px) {
                    .dashboard .level-banner-section .user-level-container .bg-container svg {
                        height: 1700px;
                    }
            }

    @media screen and (min-width: 1000px) {

    .dashboard .level-banner-section .user-level-container {
                width: 100%;
                -webkit-transform: translateX(0);
                        transform: translateX(0)
            
        }
                    .dashboard .level-banner-section .user-level-container .bg-container svg {
                        height: 1700px;
                    }
            }

    @media screen and (min-width: 1025px) and (max-width: 1170px) {

    .dashboard .level-banner-section .user-level-container {
                width: 100%;
                -webkit-transform: translateX(0);
                        transform: translateX(0)
            
        }
                    .dashboard .level-banner-section .user-level-container .bg-container svg {
                        height: 1000px;
                    }
            }

    .dashboard .level-banner-section .user-level-container .bg-container {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -2;
                height: 12rem;
                width: 100%;
                overflow: hidden;
            }

    .dashboard .level-banner-section .user-level-container .bg-container svg {
                    width: 100%;
                }

    .dashboard .level-banner-section .user-level-container .text-container {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 12rem;
                z-index: 1;
            }

    .dashboard .level-banner-section .user-level-container .text-container h2 {
                    color: white;
                    margin: 0;
                    width: 60%;
                    text-align: center;
                }

    .dashboard .level-banner-section .user-level-container .text-container span {
                    color: white;
                    margin: 0;
                    width: 20%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 2.3rem;
                    cursor: pointer;
                }

    .dashboard .tests-section {
        margin: 4rem auto 0;
    }

    .dashboard .tests-section h1 {
            font-size: 2.2rem;
            margin-bottom: 1.5rem;
            font-weight: 600;
        }

    .dashboard .tests-section .tests-container {
            display: flex;
            justify-content: center;
        }

    .dashboard .tests-section .tests-container .cStyle-container {
                width: 49%;
                text-align: center;
            }

    .dashboard .tests-section .tests-container .cStyle-container h1 {
                    line-height: normal;
                    height: 4rem;
                    font-size: 1.5rem;
                    margin: 0;
                }

    .dashboard .tests-section .tests-container .cStyle-container hr {
                    width: 25%;
                    margin: 1.5rem auto;
                }

    .dashboard .tests-section .tests-container .cStyle-container svg {
                    height: 75px;
                    width: 75px;
                    -webkit-transform: translateX(5px);
                            transform: translateX(5px);
                }

    .dashboard .tests-section .tests-container .cStyle-container img {
                    width: 30px;
                    height: 30px;
                }

    @media screen and (min-width: 1100px) {

    .dashboard .tests-section .tests-container .cStyle-container {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-around;
                    align-items: center
            }
                    
                    .dashboard .tests-section .tests-container .cStyle-container hr {
                        display: none;
                    }
                }

    .dashboard .tests-section .tests-container .assertiveness-container {
                width: 50%;
                text-align: center;
                border-left: 2px solid rgba(0,0,0, .5);
            }

    .dashboard .tests-section .tests-container .assertiveness-container h1 {
                    line-height: normal;
                    height: 4rem;
                    font-size: 1.5rem;
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

    .dashboard .tests-section .tests-container .assertiveness-container hr {
                    width: 25%;
                    margin: 1.5rem auto;
                }

    .dashboard .tests-section .tests-container .assertiveness-container svg {
                    height: 75px;
                    width: 75px;
                    -webkit-transform: translateX(5px);
                            transform: translateX(5px);
                }

    @media screen and (min-width: 1100px) {

    .dashboard .tests-section .tests-container .assertiveness-container {
                    height: 120px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center
            }
                    
                    .dashboard .tests-section .tests-container .assertiveness-container hr {
                        display: none;
                    }
                    .dashboard .tests-section .tests-container .assertiveness-container h1{
                        display: flex;
                        align-items: center;
                        margin: 0;
                    }
                }

    .dashboard .courses-section {
        margin: 4rem auto 0;
    }

    .dashboard .courses-section h1 {
            font-size: 2.2rem;
            margin-bottom: 1.5rem;
            font-weight: 600;
        }

    .dashboard .bottom-buttons-section {
        margin: 8rem auto 0;
    }

    .dashboard .bottom-buttons-section .button-primary {
            display: block;
            margin-bottom: 1rem;
        }

    @media screen and (min-width: 800px) {

    .dashboard .bottom-buttons-section {
            display: flex;
            justify-content: space-around;
            padding-bottom: 2rem
    }

            .dashboard .bottom-buttons-section .button-primary {
                margin-bottom: 0;
                width: 45%;
                transition: 1s;
            }
            .dashboard .bottom-buttons-section .button-secondary {
                width: 45%;
                transition: 1s;
            }
        }

    .dashboard .action-panel .button-primary {
            display: block;
        }

    @media (min-width: 800px) {

    .dashboard .action-panel {
            display: none
    }
        }

    @media (min-width: 800px) {

    .dashboard__section {
            flex: 1 1 50%
    }
        }

    .dashboard__row {
        display: flex;
        flex-flow: column nowrap;
    }

    @media (min-width: 800px) {

    .dashboard__row {
            flex-flow: row nowrap
    }
        }

    .dashboard .button-primary {
        display: none;
    }

    @media (min-width: 800px) {

    .dashboard .button-primary--desktop {
                display: block;
                margin: 4.8rem 0
        }
            }

    .dashboard .courses-progress-chart {
        flex-flow: column nowrap;
    }

    .dashboard .courses-progress-chart ul {
            margin: 2.8rem 0;
            align-self: flex-start;
        }

.small-btn {
    border-radius: 40px;
    padding: 8px 20px;
    color: white;
    cursor: pointer;
    border: none;
    background-color: var(--petrol);
}

.small-btn:hover {
        opacity: .6;
    }

.hide {
    display: none !important;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none; 
    user-select: none; 
  }
.horizontal-scroll {
    --gradient-width: 10rem;

    position: relative;
    display: flex;
    flex-flow: row nowrap;

    overflow-x: scroll;
}

    .horizontal-scroll::-webkit-scrollbar {
        display: none;
    }

    .horizontal-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

    /* Firefox */

    .horizontal-scroll.no-scrollbar {
        scrollbar-width: none;
    }

    /* IE and Edge */

    .horizontal-scroll.no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */

    .horizontal-scroll--full-width {
        margin-left: calc(var(--gutter) * -1);
        margin-right: calc(var(--gutter) * -1);
    }

    @media (min-width: 800px) {
        .horizontal-scroll::before {
            margin-left: calc(50% - var(--wide-page-width) / 2 + var(--gutter)) !important;
        }

        .horizontal-scroll::after {
            margin-left: calc(50% - var(--wide-page-width) / 2 + var(--gutter)) !important;
        }

        .horizontal-scroll__wrapper {
            /* width: 100%; */
            position: relative;
        }

        .horizontal-scroll__wrapper::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: calc(50% - var(--page-width) / 2 + var(--gradient-width)) !important;
            height: 100%;
            transition: opacity 0.3s;
            pointer-events: none;
            background-image: linear-gradient(to right,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 0.8) var(--gradient-width),
                    rgba(255, 255, 255, 0.95) 100%);
        }
    }

/* vertical list layout for mobile */
.horizontal-scroll__wrapper--vertical {
            margin: 0;
        }
@media (min-width: 800px) {
.horizontal-scroll__wrapper--vertical {
                margin-left: calc(var(--gutter) * -1);
                margin-right: calc(var(--gutter) * -1)
        }
            }
.horizontal-scroll--vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-x: auto;
    }
.horizontal-scroll--vertical .horizontal-scroll-item {
            margin-right: 0;
            margin-bottom: 2.8rem;
        }
@media (min-width: 800px) {
.horizontal-scroll--vertical {
            flex-direction: row;
            overflow-x: scroll;
            justify-content: flex-start
    }

            .horizontal-scroll--vertical .horizontal-scroll-item {
                margin-right: var(--gutter);
                margin-bottom: 0;
            }
        }

.horizontal-scroll::before,
.horizontal-scroll::after {
    content: "";
    width: var(--gutter);
    min-width: var(--gutter);
}

@media (min-width: 800px) {

.horizontal-scroll::before,
.horizontal-scroll::after {
        width: 1px;
        min-width: 1px
}
    }

.horizontal-scroll>li,
.topic-tile-container,
.horizontal-scroll-item {
    scroll-snap-align: center;
    margin-right: var(--gutter);
    flex: 0 0 30rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.horizontal-scroll>li:last-child, .topic-tile-container:last-child, .horizontal-scroll-item:last-child {
        margin-right: 0;
    }

@media (min-width: 800px) {

.horizontal-scroll>li,
.topic-tile-container,
.horizontal-scroll-item {
        flex: 0 0 28rem
}
    }
.section-with-badge {
    position: relative;
}

    .section-with-badge__title {
        margin-bottom: 2rem;
        position: relative;
    }

    @media (min-width: 800px) {

    .section-with-badge__title {
            margin-bottom: 4rem
    }
        }

    .section-with-badge__badge {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0.3rem;
        right: 0;
        color: var(--orange);

        font-style: normal;
        font-weight: var(--font-weight-regular);
        font-size: 2rem;
        line-height: 2.7rem;
        /* identical to box height, or 135% */

        text-align: right;
        text-align: center;
        font-size: 2rem;
        line-height: 2.7rem;
    }

    @media (min-width: 800px) {

    .section-with-badge__badge {
            display: inline-flex;
            position: relative;
            top: 0;
            margin-left: 2.4rem;
            font-size: 2.8rem;
            line-height: 3.4rem
    }
        }

    @media (min-width: 800px) {

    .section-with-badge__divider {
            margin: 4.8rem auto
    }
        }

    .section-with-badge__buttons {
        position: absolute;
        right: 0;
        top: 0;
        display: none;
    }

    /* z-index: 1; */

    @media (min-width: 800px) {

    .section-with-badge__buttons {
            display: block
    }
        }

    .section-with-badge__button {
        -webkit-appearance: none;
                appearance: none;
        border: none;
        background: transparent;
        padding: 0 0.8rem;

        right: 0;
        top: 0;
    }

    .section-with-badge__button:disabled {
            opacity: 0.5;
        }

    .section-with-badge__button--left img {
            -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
        }

    .section-with-badge.at-end .horizontal-scroll__wrapper::after {
        opacity: 0;
    }

.progress-bar {
    position: relative;
}
    /* font-size: 2.5rem; */
    .progress-bar__time-left {
        font-style: normal;
        font-weight: var(--font-weight-regular);
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: var(--dark-gray);
    }
    .progress-bar__time-right {
        position: absolute;
        right: 0;
        bottom: 0rem;
        color: var(--orange);
        font-size: 2rem;
        line-height: 2.7rem;
    }
    .progress-bar__percentage {
        font-style: normal;
        font-weight: var(--font-weight-regular);
        font-size: 20px;
        line-height: 27px;
        flex: 1 1 50%;
        color: var(--orange);
        cursor: pointer;
    }
    .progress-bar__percentage--percent {
            margin-right: 1.5rem;
        }
    .progress-bar__percentage--certified {
            color: var(--petrol);
            display: flex;
            margin-top: 1rem;
        }
    .progress-bar__percentage--certified__icon {
                margin-right: 1rem;
                width: 2.8rem;
                height: 3.3rem;
            }
    .progress-bar__percentage--label {
            font-size: 1.3rem;
            line-height: 1.8rem;
            color: var(--dark-gray);
            opacity: 0.5;
        }
    .progress-bar__value {
        flex-shrink: 1;
        margin-bottom: 0.2em;

        font-size: 2rem;
        line-height: 2.7rem;
        color: var(--orange);
    }
    .progress-bar__value--certified {
            margin-top: 1rem;
        }
    .progress-bar__wrapper {
        margin: 10px 0;
        width: 100%;
        background-color: var(--light-gray);
    }
    .progress-bar__bar {
        transition: width 0.5s;
        width: 0%;
        min-width: 0.24em;
        height: 0.6rem;

        background-color: var(--orange);
    }
    .progress-bar__info-row {
        display: flex;
        flex-flow: row nowrap;
    }

.collapsible {
    max-width: var(--wide-page-width-minus-gutter);
    margin-left: auto;
    margin-right: auto;
}

    .collapsible p {
        color: var(--dark-gray);
    }

.topics-tile {
    cursor: pointer;
}

    .topics-tile__item {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .topics-tile__item__image {
            background-color: var(--light-gray);
            width: 100%;
            padding-top: 100%;
            position: relative;
        }

    .topics-tile__item__image img {
                position: absolute;
                top: 0;
                left: 0;
            }

    .topics-tile__item__image__title {
                /* position: absolute;
                bottom: 2rem;
                left: 1.2rem;
                right: 1.2rem;
                color: var(--white);
                font-size: 3.1rem;
                line-height: 3.7rem; */

                color: var(--black);
                font-size: 2.2rem;
                width: 100%;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 1rem 0 0.5rem 0;
                line-height: 1;
                height: 4.4rem;
            }

    @media (min-width: 800px) {

    .topics-tile__item--is-detail .topics-tile__item__image {
                    height: 38rem
            }
                }

    @media (min-width: 800px) {

    .topics-tile__item__title,
        .topics-tile__item__description p {
                width: 100%;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis
        }
            }

    .topics-tile__item__description {
            display: none;
        }

    @media (min-width: 800px) {

    .topics-tile__item__description {
                display: block
        }
            }

    .topics-tile__item__description--vertical {
                display: block;
                width: 100%;
            }

    .topics-tile__item__title {
            color: var(--petrol);
            font-size: 2rem;
            line-height: 2.7rem;
            margin-top: 1.2rem;
        }

    .topics-tile__item__title--is-detail {
                margin-bottom: 2rem;
            }

    .topics-tile__item__description {
            color: var(--dark-gray);
            font-size: 1.6rem;
            line-height: 2.4rem;
            margin-top: 0.4rem;
        }

    .topics-tile__item__podcast,
        .topics-tile__item__completed-label {
            position: absolute;
        }

    .topics-tile__item__podcast {
            top: 0.8rem;
            left: 0.8rem;
        }

    .topics-tile__item__completed-label {
            top: 1rem;
            left: 1rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 4px 16px;
            font-size: 13px;
            line-height: 18px;
            font-weight: var(--font-weight-regular);
            text-transform: uppercase;

            background: #cccccc;
            border-radius: 3px;
        }

    .topics-tile__progress {
        margin-top: 1.6rem;
    }

    @media (min-width: 800px) {

    .topics-tile__progress {
            margin-top: 0
    }
        }

    .topics-tile__progress--vertical {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 2rem;
    }

    @media (min-width: 800px) {

    .topics-tile__progress--vertical {
            margin-top: 0
    }
        }

    .topics-tile__progress--vertical button {
            max-width: 17rem;
            width: 45%;
            background-color: var(--orange);
        }

    @media (min-width: 800px) {

    .topics-tile__progress--vertical button {
                display: none
        }
            }

    .topics-tile__progress--vertical .topics-tile__progress-bar {
            width: 45%;
            margin-top: 0;
        }

    @media (min-width: 800px) {

    .topics-tile__progress--vertical .topics-tile__progress-bar {
                width: 100%
        }
            }

    .topics-tile__progress-bar {
        padding: 0;
        width: 100%;
    }

    .topics-tile__progress-bar .progress-bar__wrapper {
            margin: 0.4rem 0;
        }

    .topics-tile__progress-bar .progress-bar__value {
            display: none;
        }

    @media (min-width: 800px) {

    .topics-tile__progress-bar .progress-bar__value {
                display: block
        }
            }

    .topics-tile__image {
        min-height: 20rem;
        width: 100%;
        background-color: #f2f2f2;
    }

    .topics-tile__badge {
        position: absolute;
        top: 1rem;
        left: 1rem;
        background-color: #ccc;
        font-size: 1.3rem;
        line-height: 1.8rem;
        font-weight: var(--font-weight-regular);
        padding: 0.4rem 1.6rem;
    }
.custom-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.topics .topic-tile-container {
        max-width: 20rem;
    }
    .topics h1,
    .topics h2,
    .topics h3 {
        color: var(--petrol);
        max-width: var(--wide-page-width-minus-gutter);
        margin-left: auto;
        margin-right: auto;
    }
    @media (min-width: 800px) {
    .topics h1,
    .topics h2,
    .topics h3 {
            color: var(--black)
    }
        }
    .topics h1 {
        margin-bottom: 2rem;
    }
    @media (min-width: 800px) {
    .topics h1 {
            font-size: var(--h1-big);
            line-height: 6rem
    }
        }
    .topics h1 svg {
            cursor: pointer;
        }
    .topics hr {
        max-width: var(--wide-page-width-minus-gutter);
    }
    .topics__description {
        max-width: var(--wide-page-width-minus-gutter);
        margin: 0 auto;
    }
    .topics .section-with-badge:first-of-type {
            margin-top: 2.8rem;
        }
    .topics .section-with-badge__title {
            font-size: 2.5rem;
            line-height: 3.4rem;
        }
    @media (min-width: 800px) {
    .topics .section-with-badge__title {
                font-size: 3.5rem;
                line-height: 4.2rem
        }
            }
    .topics__title {
        position: relative;
        display: flex;
        justify-content: space-between;
    }
    .topics__title >div {
            display: flex;
            align-items: center;
        }
    .topics__title >div svg:first-of-type {
                margin-right: 2.2rem;
            }
    @media (min-width: 800px) {
    .topics__title >div svg:first-of-type {
                    display: none
            }
                }
.podcast-tile {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

    .podcast-tile.clickable {
        cursor: pointer;
    }

    .podcast-tile__progress-bar {
        padding: 0;
        /* margin-top: 0; */
    }

    .podcast-tile__image {
        background-color: var(--light-gray);
        width: 100%;
        position: relative;
        padding-top: 66.53%;
    }

    .podcast-tile__image img {
            position: absolute;
            top: 0;
            left: 0;
        }

    .podcast-tile__image .play-pause-button {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            opacity: 0.8;
            z-index: 1;
        }

    .podcast-tile__image .play-pause-button circle {
                fill: var(--white);
            }

    .podcast-tile__image .play-pause-button path {
                fill: var(--petrol);
            }

    @media (min-width: 800px) {

    .podcast-tile__image--is-detail {
                width: 65rem;
                height: 38rem
        }
            }

    .podcast-tile__badge {
        position: absolute;
        top: 0.8rem;
        left: 0.8rem;
        background-color: var(--white);
        font-size: 1.2rem;
        line-height: 1.2rem;
        padding: 0.8rem 1.2rem;
        text-transform: uppercase;
        color: var(--petrol);
        border-radius: 2rem;
    }

    .podcast-tile__title {
        color: var(--petrol);
        font-size: 2rem;
        line-height: 2.7rem;
        margin-top: 1.2rem;

        width: 30rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .podcast-tile__duration-info {
        color: var(--orange);
        margin-top: 2rem;
        line-height: 2.7rem;
    }

.play-pause-button {
    cursor: pointer;
}
    .play-pause-button .icon {
        transition: opacity 0.5s;
    }
    .play-pause-button .play-icon {
        opacity: 1;
    }
    .play-pause-button .pause-icon {
        opacity: 0;
    }
    .play-pause-button.is-playing .play-icon {
            opacity: 0;
        }
    .play-pause-button.is-playing .pause-icon {
            opacity: 1;
        }

.podcasts h1,
    .podcasts h2,
    .podcasts h3 {
        color: var(--petrol);
        max-width: var(--wide-page-width-minus-gutter);
        margin-left: auto;
        margin-right: auto;
    }

        @media (min-width: 800px) {.podcasts h1,
    .podcasts h2,
    .podcasts h3 {
            color: var(--black)
    }
        }
    .podcasts h1 {
        margin-bottom: 2rem;
    }
    @media (min-width: 800px) {
    .podcasts h1 {
            font-size: var(--h1-big);
            line-height: 6rem
    }
        }
    .podcasts hr {
        max-width: var(--wide-page-width-minus-gutter);
    }
    /* hr:first-of-type {
        margin-top: 2rem;
        margin-bottom: 2.8rem;
    } */
    .podcasts .section-with-badge:first-of-type {
            margin-top: 2.8rem;
        }
    .podcasts .section-with-badge__title {
            font-size: 2.5rem;
            line-height: 3.4rem;
        }
    @media (min-width: 800px) {
    .podcasts .section-with-badge__title {
                font-size: 3.5rem;
                line-height: 4.2rem
        }
            }

@media (min-width: 800px) {.not-found {
        margin: 0 auto;
        max-width: var(--narrow-page-width)
}
    }
    .not-found h2 {
        margin: 1.7rem 0 2rem 0;
    }
    @media (min-width: 800px) {
    .not-found h2 {
            font-size: 5.5rem;
            line-height: 6rem
    }
        }
    .not-found h3 {
        color: var(--dark-gray);
        margin-bottom: 4.4rem;
    }
    .not-found hr {
        margin-bottom: 2rem;
    }
    @media (min-width: 800px) {
    .not-found hr {
            margin-bottom: 3.2rem
    }
        }
    .not-found a {
        text-decoration: underline;
        font-size: var(--p-larger);
        line-height: 2.7rem;
    }

.podcast-detail {
    padding-bottom: 10rem;
}

    @media (min-width: 800px) {.podcast-detail {
        max-width: var(--wide-page-width);
        margin: 0 auto

        /* .page-wrapper {
            padding-left: 0;
            padding-right: 0;
        } */
}
    }

    .podcast-detail hr {
        margin-top: 2.8rem;
        margin-bottom: 2.8rem;
    }

    @media (min-width: 800px) {

    .podcast-detail__tile {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-flow: row nowrap
    }

            .podcast-detail__tile hr {
                display: none;
            }
        }

    .podcast-detail__tile .podcast-tile {
            display: flex;
            flex-direction: column-reverse;
            flex: 1 1 66%;
        }

    .podcast-detail__tile .podcast-tile__title {
                display: none;
            }

    .podcast-detail__back-link {
        text-transform: uppercase;
        font-size: var(--subtitle);
        line-height: 1.6rem;
        letter-spacing: 0.1em;
        color: var(--orange);
        font-weight: var(--font-weight-regular);
    }

    @media (min-width: 800px) {

    .podcast-detail__image {
            flex: 1 1 66%
    }
        }

    @media (min-width: 800px) {

    .podcast-detail__summary {
            padding-left: 3.2rem;
            flex: 1 1 33%
    }
        }

    .podcast-detail__summary p {
            font-style: normal;
            font-weight: var(--font-weight-regular);
            font-size: 2rem;
            line-height: 2.7rem;
            color: var(--dark-gray);
            margin-top: 1.2rem;
        }

    .podcast-detail__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1.2rem 0;
    }

    .podcast-detail__title h2 {
            flex: 1 1;
        }

    .podcast-detail__title svg {
            cursor: pointer;
        }

    @media (min-width: 800px) {

    .podcast-detail__title {
            padding-bottom: 3.2rem;
            border-bottom: 4px solid var(--light-gray);
            margin-bottom: 4.8rem;
            display: flex;
            justify-content: space-between;
            align-items: center
    }

            .podcast-detail__title button {
                width: 17rem;
            }
        }

.survey-component .page-wrapper {
        padding-top: 0;
        padding-bottom: 0;
    }
    .survey-component__progress {
        padding: 1.6rem 0 1.2rem 0;
        height: var(--survey-header-height);
        border-bottom: 2px solid var(--light-gray);
        background-color: var(--white);
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 99;
    }
    @media (min-width: 800px) {
    .survey-component__progress {
            max-width: var(--page-width);
            margin: 0 auto
    }
        }
    .survey-component__progress__heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    .survey-component__progress__heading h4 {
                color: var(--petrol);
                flex: 1 1;
                max-width: 25rem;
                margin-bottom: 0.5rem;
                height: 2.7rem;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
    @media (min-width: 800px) {
    .survey-component__progress__heading h4 {
                    max-width: 50rem;
                    height: auto
            }
                }
    .survey-component__progress__heading svg {
                cursor: pointer;
            }
    .survey-component__progress .progress-bar__value {
            margin-bottom: 0;
        }
    /* survey component */
    .survey-component__survey__thanks-page {
            position: absolute;
            top: 0;
            left: 2rem;
            right: 2rem;
            width: calc(100% - 4rem);
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;   
        }
    .survey-component__survey__thanks-page__background {
                width: 100%;
                height: 100%;
                background-image: url(/static/media/rippl-shape.09eeb315.svg);
                background-position: center;
                background-repeat: no-repeat;
                position: fixed;
                left: 0;
                top: 0;
                z-index: -1;
            }
    .survey-component__survey__thanks-page h1 {
                font-size: 30px;
                text-align: center;
                z-index: 1;
                color: var(--black);
                margin-bottom: 4.4rem;
                max-width: var(--narrow-page-width);
            }
    @media (min-width: 800px) {
                .survey-component__survey__thanks-page h1 {
                    font-size: var(--h1);
                }
            }
    @media screen and (max-width: 600px) and (max-height: 700px) {
                .survey-component__survey__thanks-page h1 {
                    font-size: 20px;
                    line-height: 1.6;
                    margin-bottom: 20px;
                    padding-top: 25px;
                    padding-bottom: 25px;
                }
            }
    .survey-component__survey {

        min-height: var(--survey-min-height);
}
    .survey-component__survey strong {
            font-weight: normal;
            color: var(--orange);
        }
    .survey-component__survey .sv_main button {
            min-width: auto;
            line-height: 0;
        }
    .survey-component__survey .sv_main button:hover {
                background: none;
            }
    /* style for surveyjs default elements */
    .survey-component__survey .sv_main .sv_body,
        .survey-component__survey .sv_main .sv_p_root > .sv_row {
            border: none;
        }
    .survey-component__survey .sv_body {
            font-family: var(--font-family);
            font-weight: var(--font-weight-regular);
            font-size: var(--p-larger);
            line-height: 2.7rem;
            color: var(--dark-gray);
        }
    .survey-component__survey .sv_main {
            padding-bottom: 12rem;
            background-color: var(--white);
        }
    .survey-component__survey .sv_main .sv_custom_header {
                background-color: transparent;
            }
    @media (min-width: 800px) {
    .survey-component__survey .sv_main {
                padding-bottom: 12rem;
                width: 60rem;
                margin: 0 auto
        }
            }
    @media (min-width: 1440px) {
    .survey-component__survey .sv_main {
                width: 70rem
        }
            }
    .survey-component__survey .sv_main,
        .survey-component__survey .sv_body .sv_p_root > .sv_row,
        .survey-component__survey .sv_main .sv_body,
        .survey-component__survey .sv_main .sv_p_root > .sv_row:nth-child(odd),
        .survey-component__survey .sv_main .sv_p_root > .sv_row:nth-child(even) {
            background-color: transparent;
        }
    .survey-component__survey .sv_page_title {
            margin-bottom: 2.2rem;
        }
    .survey-component__survey .sv_main .sv_container .sv_completed_page {
            display: none;
        }
    .survey-component__survey .sv_main .sv_container .sv_body,
        .survey-component__survey .sv_main .sv_container .sv_body .sv_p_root .sv_q {
            padding: 0;
        }
    .survey-component__survey .sv_main .sv-title-actions__title {
            flex-wrap: wrap;
            max-width: 100%;
            min-width: 50%;
        }
    .survey-component__survey .sv_main .sv_container .sv_body .sv_p_root fieldset.sv_qcbc {
            margin-top: 1rem !important;
        }
    .survey-component__survey .sv_main .sv_container .sv_body .sv_p_root fieldset.sv_qcbc sv-q-col-1 {
                margin: 0 !important;
            }
    .survey-component__survey .sv_main .sv_container .sv_body .sv_p_root fieldset.sv_qcbc div {
                margin-bottom: .5rem;
            }
    .survey-component__survey .sv_main .sv_container .sv_body .sv_p_root fieldset.sv_qcbc div label {
                    cursor: pointer;
                    font-size: 1.7rem;
                }
    .survey-component__survey .sv_main .sv_container .sv_body .sv_p_root fieldset.sv_qcbc div label .circle:before {
                            border-color: var(--orange);
                            border-radius: 50%;
                            border-style: solid;
                            border-width: 2px;
                            height: 2.5rem;
                            width: 2.5rem;
                        }
    .survey-component__survey .sv_main .sv_container .sv_body .sv_p_root.sv_p_swipe > .sv_row {
            margin-bottom: 0;
        }
    /* style default surveyjs header in case class isn't applied on first load */
    .survey-component__survey .sv_main .sv_container .sv_header {
            padding: 0;
            padding-bottom: 2.8rem;
        }
    .survey-component__survey .sv_main .sv_container .sv_header h3 span {
            font-size: var(--h2);
            font-weight: var(--font-weight-regular);
            line-height: 3.7rem;
            color: var(--black);
            margin-bottom: 2.8rem;
        }
    .survey-component__survey .custom-html-elements iframe {
                width: 100%;
                height: 58rem;
                margin: 2.5rem 0;
            }
    .survey-component__survey .custom-html-elements p {
                margin-bottom: 1.2rem;
                font-size: var(--p-larger);
                line-height: 2.7rem;
                color: var(--dark-gray);
            }
    .survey-component__survey .custom-sv-header .custom-sv-title {
                font-size: var(--h2);
                font-weight: var(--font-weight-regular);
                line-height: 3.7rem;
                color: var(--black);
                margin-bottom: 2.8rem;
            }
    .survey-component__survey .custom-sv-question-title {
            font-size: var(--h4);
            line-height: 2.7rem;
            color: var(--black);
            padding-bottom: 2rem;
            border-bottom: 2px solid var(--light-gray);
            display: flex;
            align-items: flex-end;
            min-height: calc((27px * 2) + 20px + 2px);
            margin-top: 2.4rem;
        }
    @media (min-width: 800px) {
    .survey-component__survey .custom-sv-question-title {
                min-height: calc((27px * 2) + 32px + 2px);
                padding-bottom: 3.2rem
        }
            }
    .survey-component__survey .custom-sv-radiogroup-item {
            margin-bottom: 2rem;
        }
    .survey-component__survey .custom-sv-radiogroup {
            font-size: var(--p-larger);
            line-height: 2.7rem;
            font-weight: var(--font-weight-light);
            color: var(--dark-gray);
            display: flex;
            align-items: center;
        }
    .survey-component__survey .custom-sv-radiogroup svg {
                display: none;
            }
    .survey-component__survey .custom-sv-radiogroup input[type="radio"] {
                display: none;
            }
    .survey-component__survey .custom-sv-radiogroup input[type="radio"] + *::before {
                content: "";
                display: inline-block;
                vertical-align: bottom;
                width: 2rem;
                height: 2rem;
                margin-right: 1.2rem;
                border-radius: 50%;
                border-style: solid;
                border-width: 1px;
                border-color: var(--mid-gray);
            }
    .survey-component__survey .custom-sv-radiogroup input[type="radio"]:checked + * {
                color: var(--petrol);
            }
    .survey-component__survey .custom-sv-radiogroup input[type="radio"]:checked + *::before {
                background: radial-gradient(
                    var(--petrol) 0%,
                    var(--petrol) 40%,
                    transparent 50%,
                    transparent
                );
                border-color: var(--petrol);
            }
    .survey-component__survey .sv-ranking {
            margin-top: 10rem;
            padding-bottom: 5rem;
        }
    @media screen and (max-height: 700px) {
    .survey-component__survey .sv-ranking {
                margin-top: 3rem;
                padding-bottom: 2rem
        }
            }
    .survey-component__survey .sv-ranking .sv-ranking-item {
                margin-bottom: 2rem;
            }
    .survey-component__survey .sv-ranking .sv-ranking-item__icon-container {
                    width: 100%;
                }
    .survey-component__survey .sv-ranking .sv-ranking-item__icon-container svg {
                        display: none;
                    }
    .survey-component__survey .sv-ranking .sv-ranking-item__content {
                    padding: 0;
                    display: flex;
                    align-items: center;
                }
    .survey-component__survey .sv-ranking .sv-ranking-item__text {
                    font-size: var(--p);
                    color: var(--dark-gray);
                    margin-left: 1.2rem;
                    font-weight: var(--font-weight-light);
                }
    .survey-component__survey .sv-ranking .sv-ranking-item__index {
                    background-color: var(--white);
                    padding: 0.7rem 1.3rem;
                    border: 1px solid var(--orange);
                    border-radius: 100px;
                    width: 3.6rem;
                    height: 3.6rem;
                    flex: 0 0 3.6rem;
                    color: var(--orange);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                }
    .survey-component__survey .sv-ranking .sv-ranking-item--ghost {
                    background-color: var(--light-gray);
                    border-radius: 30px;
                    padding: 1rem;
                }
    .survey-component__survey .sv-ranking .sv-ranking-item--ghost .custom-sv-item__content {
                        opacity: 0;
                    }
    .survey-component__survey .sv-ranking .sv-ranking-item__ghost {
                    display: none;
                }
    .survey-component__survey .custom-sv-ranking-drag--drag {
                background-color: var(--white);
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
                border-radius: 30px;
                padding: 1rem;
            }
    .survey-component__survey .custom-sv-footer {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            background: var(--white);
            border-radius: 3.2rem 3.2rem 0 0;
            padding: 2rem;
            -webkit-filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
                    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
            display: flex;
            z-index: 199;
        }
    @media (min-width: 800px) {
    .survey-component__survey .custom-sv-footer {
                left: 50%;
                width: calc(100% - var(--narrow-page-padding));
                -webkit-filter: none;
                        filter: none;
                border-radius: 0;
                -webkit-transform: translateX(-50%);
                        transform: translateX(-50%);
                border-top: 2px solid var(--light-gray);
                display: flex;
                justify-content: center
        }

                .survey-component__survey .custom-sv-footer input {
                    max-width: 30rem;
                }
            }
    .survey-component__survey .custom-sv-footer .custom-sv-next,
            .survey-component__survey .custom-sv-footer .custom-sv-prev,
            .survey-component__survey .custom-sv-footer .custom-sv-complete {
                border-radius: 3.2rem;
                padding: 1.5rem 0;
                font-size: var(--p-larger);
                line-height: 2.7rem;
                margin: 0;
                font-family: var(--font-family);
                font-weight: var(--font-weight-regular);
            }
    .survey-component__survey .custom-sv-footer .custom-sv-next,
            .survey-component__survey .custom-sv-footer .custom-sv-complete {
                background-color: var(--petrol);
                border: none;
                color: var(--white);
                margin-left: 1.5rem;
            }
    .survey-component__survey .custom-sv-footer .custom-sv-next:hover, .survey-component__survey .custom-sv-footer .custom-sv-complete:hover {
                    background-color: var(--petrol);
                    opacity: 0.6;
                }
    @media (hover: none) {
    .survey-component__survey .custom-sv-footer .custom-sv-next:hover, .survey-component__survey .custom-sv-footer .custom-sv-complete:hover {
                        opacity: 1
                }
                    }
    .survey-component__survey .custom-sv-footer .custom-sv-next--disabled, .survey-component__survey .custom-sv-footer .custom-sv-complete--disabled {
                    background-color: var(--light-gray);
                    color: var(--mid-gray);
                }
    .survey-component__survey .custom-sv-footer .custom-sv-next--disabled:hover, .survey-component__survey .custom-sv-footer .custom-sv-complete--disabled:hover {
                        opacity: 1;
                        background-color: var(--light-gray);
                        cursor: auto;
                    }
    .survey-component__survey .custom-sv-footer .custom-sv-prev {
                background-color: var(--white);
                border: 2px solid var(--petrol);
                color: var(--petrol);
            }
    .survey-component__survey .custom-sv-footer .custom-sv-prev:hover {
                    background-color: var(--white);
                    opacity: 0.6;
                }
    @media (hover: none) {
    .survey-component__survey .custom-sv-footer .custom-sv-prev:hover {
                        opacity: 1
                }
                    }

#custom-span {
    font-size: var(--h1);
    text-align: center;
    z-index: 1;
    color: var(--black);
    margin-bottom: 4.4rem;
    max-width: var(--narrow-page-width);
}

@media screen and (max-width: 700px) {
    #custom-span {
        font-size: normal;
        max-width: 85%;
    }
}
.assertive-results-page h3 {
        color: var(--black);
        margin-bottom: 1.5rem;
    }

        @media (min-width: 800px) {.assertive-results-page h3 {
            margin-bottom: 3.2rem
    }
        }
    .assertive-results-page p {
        margin-bottom: 1.2rem;
        font-size: var(--p-larger);
        line-height: 2.7rem;
        color: var(--dark-gray);
    }
    @media (min-width: 800px) {
    .assertive-results-page p {
            line-height: 3.2rem
    }
        }
    .assertive-results-page p + p {
        margin-top: 4.8rem;
    }
.assertive-results-chart {
    margin-bottom: 4.8rem;
}
.assertive-results-chart hr {
        border: none;
        border-top: 0.2rem solid var(--light-gray);
        margin: 4rem auto;
    }
.assertive-results-chart__heading {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }
.assertive-results-chart__heading h3 {
            margin-bottom: 0;
        }
.assertive-results-chart__heading__toggle {
            display: flex;
            align-items: center;
        }
.assertive-results-chart__heading__toggle svg {
                margin-right: 0.8rem;
            }
.assertive-results-chart__heading__toggle p {
                color: var(--orange);
                text-decoration: underline;
                cursor: pointer;
                font-size: var(--p);
                margin: 0;
            }
.assertive-results-chart__definitions {
        margin-top: 2.2rem;
    }
.assertive-results-chart__definitions h4 {
            color: var(--petrol);
            margin-bottom: 1.2rem;
        }
.assertive-results-chart__definitions p {
            margin-bottom: 4.8rem;
            font-size: var(--p-larger);
        }
@media (min-width: 800px) {
.assertive-results-chart__definitions p {
                line-height: 3.2rem
        }
            }
.assertive-results-chart__item-name {
        color: var(--petrol);
        font-size: 1.3rem;
        line-height: 1.6rem;
        text-transform: uppercase;
        margin-top: 2.7rem;
        margin-bottom: 0.8rem;
        letter-spacing: 0.1em;
    }
.assertive-results-chart__item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
.assertive-results-chart__item-bar-wrapper {
        flex: 1 1 100%;
        background-color: var(--light-gray);
        height: 2rem;
    }
.assertive-results-chart__item-bar {
        background-color: var(--orange);
        height: 100%;
    }
.assertive-results-chart__item-percent {
        flex: 0 0 5.6rem;
        font-size: 2rem;
        line-height: 2.7rem;
        color: var(--orange);
        text-align: right;
    }

.flip-card {
    margin-top: 4rem;
    -webkit-filter: drop-shadow(0px 0px 0.5rem rgba(0, 0, 0, 0.15));
            filter: drop-shadow(0px 0px 0.5rem rgba(0, 0, 0, 0.15));
}

    .flip-card:last-of-type {
        margin-bottom: 8rem;
    }

    @media (min-width: 800px) {

    .flip-card:last-of-type {
            margin-bottom: 4rem
    }
        }

    .flip-card .front,
    .flip-card .back {
        border-radius: 12px;
        background-color: var(--white);
        height: 42rem;
        overflow: hidden;
        cursor: pointer;
    }

    @media (min-width: 800px) {

    .flip-card .front,
    .flip-card .back {
            height: 50.2rem
    }
        }

    .flip-card .front:before, .flip-card .back:before {
            content: "";
            width: 100%;
            height: 12rem;
            position: absolute;
            bottom: 7.8rem;
            left: 0;
            background: var(--gradient-white);
        }

    .flip-card .front:after, .flip-card .back:after {
            content: "";
            width: calc(100% - 4rem);
            height: 2px;
            background-color: var(--light-gray);
            position: absolute;
            bottom: 7.8rem;
            left: 2rem;
        }

    .flip-card .front__content, .flip-card .back__content {
            padding: 3.6rem 2.2rem 9rem 2.2rem;
            width: 100%;
            height: 100%;
            overflow-y: scroll;
        }

    .flip-card .front__content .card-title, .flip-card .back__content .card-title {
                line-height: normal;
                font-size: 2rem;
                margin-bottom: 2rem;
            }

    @media screen and (min-width: 600px) {

    .flip-card .front__content .card-title, .flip-card .back__content .card-title {
                    font-size: 2.4rem
            }
                }

    .flip-card .front__content .card-content p, .flip-card .back__content .card-content p {
                    font-size: 1.6rem !important;
                }

    .flip-card .front__content .card-content ul, .flip-card .back__content .card-content ul {
                    margin: 0;
                    list-style: disc;
                    padding-left: 1rem;
                    padding-bottom: 5rem;
                }

    .flip-card .front__content .card-content ul li, .flip-card .back__content .card-content ul li {
                        font-size: 1.5rem;
                        margin: 0.5rem 0;
                    }

    @media screen and (min-width: 600px) {
                    .flip-card .front__content .card-content p, .flip-card .back__content .card-content p {
                        font-size: 2rem !important;
                    }
                    .flip-card .front__content .card-content ul, .flip-card .back__content .card-content ul {
                        padding-left: 2rem;
                    }

                        .flip-card .front__content .card-content ul li, .flip-card .back__content .card-content ul li {
                            margin: 1rem 0;
                            font-size: 1.9rem;
                        }
                }

    .flip-card .front__cta, .flip-card .back__cta {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 7.8rem;
            padding: 0 2rem;
            background-color: var(--white);
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            border-radius: 0px 0px 12px 12px;
        }

    .flip-card .front__cta:before, .flip-card .back__cta:before {
                content: "Flip card";
                color: var(--orange);
                font-size: var(--p);
                line-height: 2rem;
                letter-spacing: 0.1em;
                text-transform: uppercase;
            }

    .flip-card .front__cta:after, .flip-card .back__cta:after {
                content: url(/static/media/flip.ad0e1de3.svg);
            }

    .flip-card .front p:first-of-type, .flip-card .back p:first-of-type {
            font-size: var(--h4);
            line-height: 2.7rem;
            font-weight: var(--font-weight-regular);
            color: var(--black);
            margin-bottom: 2.5rem;
        }

    .flip-card .front p:last-of-type, .flip-card .back p:last-of-type {
            padding-bottom: 8rem;
        }

    .flip-card .back p,
        .flip-card .back p:first-of-type {
            color: var(--petrol);
        }

.jsx-parser.flip-cards p {
            font-size: var(--p-larger);
            line-height: 2.7rem;
        }

@media (min-width: 800px) {

.jsx-parser.flip-cards p {
                line-height: 3.2rem
        }
            }

.sv_row .jsx-parser.flip-cards {
            padding: 0 0.7rem;
        }

.tip > h4 {
        margin: 2rem 0;
    }

        @media (min-width: 800px) {.tip > h4 {
            margin: 2.4rem 0
    }
        }
    .tip > p {
        font-size: var(--p);
        line-height: 2.4rem;
        font-weight: var(--font-weight-light);

        font-size: var(--p-larger);
        line-height: 2.7rem;
    }
    @media (min-width: 1024px) {
    .tip > p {
            line-height: 3.2rem
    }
        }
    .tip--cstyle {
        margin-top: 3rem;
    }
    @media (min-width: 800px) {
    .tip--cstyle {
            margin-top: 7.3rem
    }
        }
    .tip--cstyle .subtitle {
            margin-bottom: 0.8rem;
            font-size: var(--p-smaller);
            line-height: 1.6rem;
        }
    @media (min-width: 800px) {
    .tip--cstyle .subtitle {
                font-size: var(--p);
                line-height: 2.2rem
        }
            }
    .tip--cstyle h3 {
            text-transform: capitalize;
            color: var(--petrol);
        }
    @media (min-width: 800px) {
    .tip--cstyle h3 {
                font-size: 3.5rem;
                line-height: 4.2rem
        }
            }
    .tip--cstyle p {
            font-size: var(--p);
            line-height: 2.7rem;
        }
    @media (min-width: 800px) {
    .tip--cstyle p {
                font-size: var(--p-larger);
                line-height: 3.2rem
        }
            }
    .tip--cstyle__banner-wrapper {
            height: 3.7rem;
            position: relative;
            overflow: hidden;
            border-radius: 13px;
            margin: 2.1rem 0;
        }
    @media (min-width: 800px) {
    .tip--cstyle__banner-wrapper {
                height: 6.4rem;
                margin: 3.2rem 0
        }
            }
    .tip--cstyle__banner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    .tip--cstyle__banner svg {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
    .tip--answer {
        font-weight: bold;
        color: black;
        margin: 2rem 0;
    }
    @media (min-width: 800px) {
    .tip--answer {
            margin: 2.4rem 0
    }
        }
    .tip--question.wrong {
            color: #f53b3b;
        }
    .tip--question::after {
            content: "";
            display: block;
            margin: 2.4rem 0;
            height: 0.2rem;
            background: #f0f0f0;
        }
    .tip--label {
        color: var(--dark-gray);
        margin-bottom: 1.6rem;
    }
    .tip--content p {
            margin: 0;
            font-weight: var(--font-weight-light);

            font-size: var(--p-larger);
            line-height: 2.7rem;
        }
    @media (min-width: 1024px) {
    .tip--content p {
                line-height: 3.2rem
        }
            }
    .tip--content h4 {
            margin-bottom: 2.2rem;
        }

.pagination--tips .tip {
    margin-top: 2.4rem;
}

.pagination--tips .tip--label {
        color: var(--dark-gray);
        margin-bottom: 1.4rem;
    }

.introduction {
    width: 100%;
    padding-top: 1.8rem;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    min-height: var(--survey-min-height);
}

    @media (min-width: 800px) {.introduction {
        padding-bottom: 0;
        justify-content: center
}
    }

    .introduction .introduction-inner__content h3 {
                color: var(--black);
                margin-bottom: 3.4rem;
                width: 100%;
            }

    .introduction .introduction-inner__content p {
                width: 100%;
                font-weight: var(--font-weight-regular);
            }

    @media (min-width: 768px) {

    .introduction .introduction-inner__content p {
                    font-weight: var(--font-weight-light)
            }
                }

    @media (min-width: 800px) {

    .introduction .introduction-inner__content p {
                    font-size: var(--p-larger);
                    line-height: 3.4rem
            }
                }

    .introduction .introduction-inner__content--video {
                padding-bottom: 90%;
            }

    @media (min-width: 800px) {

    .introduction .introduction-inner__content--video {
                    padding-bottom: 0
            }
                }

    @media (min-width: 768px) {

    .introduction .introduction-inner__content--video h3 {
                        width: 60%
                }
                    }

    .introduction .introduction-inner__content--video p {
                    width: 50%;
                    position: absolute;
                    right: 0;
                    top: 25rem;
                }

    @media (min-width: 768px) {

    .introduction .introduction-inner__content--video p {
                        width: 60%;
                        position: static
                }
                    }

    .introduction .introduction-inner .video-wrapper {
            position: absolute;
            top: 20rem;
            left: 0;
        }

    @media (min-width: 768px) {

    .introduction .introduction-inner .video-wrapper {
                top: 50%;
                right: 0;
                left: auto;
                -webkit-transform: translateY(-50%);
                        transform: translateY(-50%)
        }
            }

.video-wrapper {
    width: 14rem;
    height: 24rem;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
}

    @media (min-width: 768px) {.video-wrapper {
        width: 23rem;
        height: 40rem;
        border-radius: 24px
}
    }

    .video-wrapper iframe {
        width: 100%;
        height: 100%;
    }

    .video-wrapper__overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        z-index: 2;
    }

.video-modal {
    background-color: rgba(255, 255, 255, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
}

.video-modal__video-wrapper {
        left: 50%;
        right: auto;
        top: 5rem;
        bottom: 5rem;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        position: fixed;
        border-radius: 14px;
        overflow: visible; /* explicitly set overflow visible for Safari */
        -webkit-mask-image: radial-gradient(white, black);
                mask-image: radial-gradient(white, black);

        background-color: var(--black);
    }

@media (max-width: 360px) {

.video-modal__video-wrapper {
            min-width: calc(100% - (var(--gutter) * 4))
    }
        }

@media (min-width: 800px) {

.video-modal__video-wrapper {
            border-radius: 24px
    }
        }

.video-modal__video-wrapper--close {
            position: absolute;
            top: 1rem;
            right: 1rem;
            cursor: pointer;
            z-index: 10;
            padding: 1rem;
            font-size: 4rem;
        }

.video-modal__video-wrapper--close:hover {
                opacity: .5;
            }

.video-modal__video-wrapper canvas {
            height: 100%;
        }

.video-modal__vimeo {
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
    }

.video-modal__vimeo iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

.personalised-video {
  position: relative;
  height: 20rem;
  width: 100%;
  cursor: pointer;
  border-radius: 15px;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: .5s;
}

  @media screen and (min-width: 600px) {.personalised-video {
    height: 35rem
}
  }

  .personalised-video:hover {
    opacity: .7;
    transition: .5s;
  }

  .personalised-video:hover svg {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
      transition: .5s;
    }

  .personalised-video svg {
    transition: .5s;
    position: absolute;
    color: var(--orange);
    top: calc(50% - 3rem);
    left: calc(50% - 3rem);
    height: 6rem;
    width: 6rem;
  }

.connecting-preview {
  background-image: url(/static/media/Connecting-preview.dead4ef0.png);
  background-size: cover;
}

.examining-preview {
  background-image: url(/static/media/Examining-preview.4f43e0ec.png);
}

.influencing-preview {
  background-image: url(/static/media/Influencing-preview.09b10b89.png);

}

.delivering-preview {
  background-image: url(/static/media/Delivering-preview.78b9bee1.png);
}

.Assertiveness-connecting-preview {
  background-image: url(/static/media/Assertiveness-Connecting-preview.97707de3.png);
  background-size: 100% 100%;
}

.Assertiveness-examining-preview {
  background-image: url(/static/media/Assertiveness-Examining-preview.ce4316e4.png);
  background-size: 100% 100%;
}

.Assertiveness-influencing-preview {
  background-image: url(/static/media/Assertiveness-Influencing-preview.0c41836e.png);
  background-size: 100% 100%;
}

.Assertiveness-delivering-preview {
  background-image: url(/static/media/Assertiveness-Delivering-preview.654cf68d.png);
  background-size: 100% 100%;
}

.PWC-preview {
  background-image: url(/static/media/PWC-preview.9f4ba2e3.png);
  background-size: 100% 100%;
}

.PWC2-preview {
  background-image: url(/static/media/PWC2-preview.2de28f1b.png);
  background-size: 100% 100%;
}

.PYP-preview {
  background-image: url(/static/media/PYP.a3778fff.png);
  background-size: 100% 100%;
}

.HTSP-preview {
  background-image: url(/static/media/HTSP.b4231b28.png);
  background-size: 100% 100%;
}

.HTSP2-preview {
  background-image: url(/static/media/HTSP2.d2558630.png);
  background-size: 100% 100%;
}

.HTDGS-preview {
  background-image: url(/static/media/HTDGS.40287a77.png);
  background-size: 100% 100%;
}

.HTPWB-preview {
  background-image: url(/static/media/HTPWB.22bc9f06.png);
  background-size: 100% 100%;
}

.HTHO1-preview {
  background-image: url(/static/media/HTHO1.88a38754.png);
  background-size: 100% 100%;
}

.HTHO2-preview {
  background-image: url(/static/media/HTHO2.e2cde86b.png);
  background-size: 100% 100%;
}
.instructions {
    padding-top: 1.8rem;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: var(--survey-min-height);

    /* &__background {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;

        svg {
            width: 100%;
            height: 100%;
            transform: scale(2.5);

            @media (--tablet) {
                transform: scale(1);
            }

            @media (--big-screen) {
                transform: scale(0.7);
            }
        }

        @media (--tablet) {
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    } */
}

    @media (min-width: 800px) {.instructions {
        justify-content: center
}
    }

    .instructions__content__background {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background-image: url(/static/media/rippl-shape.09eeb315.svg);
            background-repeat: no-repeat;
            background-position: center;
            z-index: -1;
        }

    .instructions__content h3 {
            color: var(--petrol);

            margin-bottom: 2rem;
        }

    @media (min-width: 800px) {

    .instructions__content h3 {
                margin-bottom: 3.8rem
        }
            }

    .instructions__content p {
            font-size: var(--p-larger);
            line-height: 2.7rem;
            color: var(--dark-gray);
            font-weight: var(--font-weight-regular);
        }

    @media (min-width: 800px) {

    .instructions__content p {
                line-height: 3.4rem;
                font-weight: var(--font-weight-light)
        }
            }

    .instructions__content--image h3:first-of-type {
                margin-top: 0;
            }

    .instructions__content--image__wrapper {
                width: 100%;
                max-width: 50rem;
                margin-top: 3.4rem;
            }

    .instructions__content--image__wrapper img {
                    object-fit: contain;
                }

    @media screen and (min-width: 600px) {
                .instructions__content .instructions-container p {
                    font-size: 2.5rem;
                    margin-bottom: 3rem;
                    font-weight: var(--font-weight-regular);
                }
            }

    .instructions__content .instructions-container ul {
                list-style-type: disc;
                padding-left: 3rem;
                margin-top: 1.5rem;
            }

    .instructions__content .instructions-container ul li {
                    margin: 1rem 0;
                }

    .instructions__content .unordered-list-instructions h1 {
                line-height: 1;
                font-size: 2.5rem;
                margin: 3rem 0;
            }

    .instructions__content .unordered-list-instructions ul {
                list-style-type: disc;
                padding: 0 2rem;
                width: 90%;
                margin: 0 auto 3rem auto;
            }

    .instructions__content .unordered-list-instructions p,
            .instructions__content .unordered-list-instructions li,
            .instructions__content .unordered-list-instructions h1 {
                width: 100%;
                font-weight: var(--font-weight-regular);
                font-size: var(--p);
            }

    @media (min-width: 768px) {

    .instructions__content .unordered-list-instructions p,
            .instructions__content .unordered-list-instructions li,
            .instructions__content .unordered-list-instructions h1 {
                    font-weight: var(--font-weight-light)
            }
                }

    @media (min-width: 800px) {

    .instructions__content .unordered-list-instructions p,
            .instructions__content .unordered-list-instructions li,
            .instructions__content .unordered-list-instructions h1 {
                    font-size: var(--p-larger);
                    line-height: 3.4rem
            }
                }
.pagination {
    padding-top: 1.8rem;
    padding-bottom: 8rem;
    min-height: calc(var(--survey-min-height) - 10rem);
}

    @media (min-width: 800px) {.pagination {
        padding-top: 0;
        padding-bottom: 0
}
    }

    .pagination--video {
        display: flex;

        align-items: center;
        flex-direction: column-reverse;
        justify-content: flex-end;
        width: 100%;

        min-height: calc(var(--survey-min-height));
    }

    @media (min-width: 800px) {

    .pagination--video {
            flex-direction: row;
            justify-content: space-between
    }
        }

    .pagination--video__content {
            width: 100%;
            margin-bottom: 5rem;
        }

    @media (min-width: 800px) {

    .pagination--video__content {
                flex: 1 1;
                margin-left: 3rem
        }
            }

    .pagination--video__content__copy {
                min-height: 10rem;
                display: flex;
                flex-direction: column;
                padding-bottom: 1.6rem;
                border-bottom: 2px solid var(--light-gray);
            }

    @media (min-width: 800px) {

    .pagination--video__content__copy {
                    min-height: 12rem;
                    justify-content: flex-end;
                    padding-bottom: 4.8rem;
                    margin-bottom: 0
            }
                }

    .pagination--video__content__copy h3 {
                    color: var(--black);
                }

    /* here used with the same style as subtitles */

    .pagination--video__content__copy h4 {
                    font-size: var(--subtitle);
                    line-height: 1.6rem;
                    text-transform: uppercase;
                    letter-spacing: 0.1em;
                    color: var(--orange);
                    font-weight: var(--font-weight-regular);
                    margin-bottom: 0.8rem;
                }

    @media (min-width: 800px) {

    .pagination--video__content__copy h4 {
                        font-size: var(--p);
                        line-height: 2.2rem
                }
                    }

    .pagination--video__video-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            width: 100%;
            height: 100%;
        }

    @media (min-width: 800px) {

    .pagination--video__video-container {
                margin-top: 0;
                display: block;
                width: auto;
                height: auto
        }
            }

    .pagination--video__video-container .pagination__controls--mobile-controls {
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
            }

    .pagination--video__video-container .pagination__controls--mobile-controls .pagination__controls__arrows {
                    display: flex;
                    justify-content: space-between;
                }

    .pagination--video__video-container .pagination__controls--mobile-controls p,
                .pagination--video__video-container .pagination__controls--mobile-controls span {
                    display: none;
                }

    @media (min-width: 800px) {

    .pagination--video__video-container .pagination__controls--mobile-controls {
                    display: none
            }
                }

    .pagination--video__video-container .video-wrapper {
                z-index: 1;
            }

    @media (min-width: 800px) {

    .pagination--video__video-container .video-wrapper {
                    position: relative;
                    -webkit-transform: none;
                            transform: none;
            }
                }

    .pagination--tips {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    @media (min-width: 800px) {

    .pagination--tips {
            margin-top: 5rem
    }
        }

    .pagination--tips__heading {
            border-bottom: 2px solid var(--light-gray);
            margin-bottom: 1.6rem;
            padding-bottom: 1.6rem;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            min-height: calc(27px * 2 + 16px * 2 + 2px);
        }

    @media (min-width: 800px) {

    .pagination--tips__heading {
                margin-bottom: 2.4rem;
                padding-bottom: 2.2rem;
                min-height: calc(34px * 2 + 40px * 2 + 2px)
        }
            }

    .pagination--tips__heading .subtitle {
                margin-bottom: 1.6rem;
                color: var(--dark-gray);
            }

    @media (min-width: 800px) {

    .pagination--tips__heading .subtitle {
                    margin-bottom: 2.2rem
            }
                }

    .pagination--tips__heading h4 {
                color: var(--black);
            }

    @media (min-width: 800px) {

    .pagination--tips__heading h4 {
                    font-size: 2.8rem
            }
                }

    .pagination--tips__heading--wrong h4 {
                    color: var(--red);
                }

    .pagination--tips__no-tips {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: calc(var(--survey-min-height) - 10rem);
        }

    .pagination--tips__no-tips h3 {
                color: var(--petrol);

                margin-bottom: 2rem;
            }

    @media (min-width: 800px) {

    .pagination--tips__no-tips h3 {
                    margin-bottom: 3.8rem
            }
                }

    .pagination--tips__no-tips p {
                font-size: var(--p-larger);
                line-height: 2.7rem;
                color: var(--dark-gray);
                font-weight: var(--font-weight-regular);
            }

    @media (min-width: 800px) {

    .pagination--tips__no-tips p {
                    line-height: 3.4rem;
                    font-weight: var(--font-weight-light)
            }
                }

    .pagination--tips__no-tips__background {
                width: 100%;
                height: 100%;
                position: fixed;
                top: 0;
                left: 0;
                background-image: url(/static/media/rippl-shape.09eeb315.svg);
                background-repeat: no-repeat;
                background-position: center;
                z-index: -1;
            }

    .pagination__controls--video {
            display: none;
        }

    @media (min-width: 800px) {

    .pagination__controls--video {
                display: flex;
                width: auto;
                -webkit-transform: none;
                        transform: none;
                position: static;
                margin-top: 2.4rem
        }
            }

    .pagination__controls--video .pagination__controls__arrows {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

    @media (min-width: 800px) {

    .pagination__controls--video .pagination__controls__arrows {
                    width: auto;
                    display: block
            }
                }

    .pagination__controls--video p,
            .pagination__controls--video span {
                display: none;
            }

    @media (min-width: 800px) {

    .pagination__controls--video p,
            .pagination__controls--video span {
                    display: inline-block
            }
                }

    .pagination__controls--tips {
            display: flex;
        }

.pagination__controls {
    align-items: center;
    justify-content: space-between;
}

    .pagination__controls__arrows button {
            background: none;
            border: none;
            margin: 0;
            padding: 0;
            cursor: pointer;
            transition: opacity 0.2s ease-in-out;
        }

    .pagination__controls__arrows button:disabled {
                opacity: 0.3;
                cursor: auto;
            }

    .pagination__controls__arrows button:disabled:hover {
                    opacity: 0.3;
                }

    .pagination__controls__arrows button:hover {
                opacity: 0.8;
                transition: opacity 0.2s ease-in-out;
            }

    .pagination__controls__arrows button:first-of-type {
                -webkit-transform: scaleX(-1);
                        transform: scaleX(-1);
                margin-right: 1.6rem;
            }

    .pagination__controls__skip {
        border-radius: 3.2rem;
        padding: 1.5rem 0;
        font-size: 2rem;
        background-color: var(--white);
        border: 2px solid var(--petrol);
        color: var(--petrol);
        padding: 1rem;
        width: 10rem;
        height: 4.8rem;
        text-align: center;
        cursor: pointer;
        margin-bottom: 0.5rem;
    }

    @media (min-width: 800px) {

    .pagination__controls__skip {
            width: 13.8rem
    }
        }

    .pagination__controls__skip:hover {
            opacity: 0.7;
        }

    .pagination__controls p {
        color: var(--mid-gray);
    }

    .pagination__controls span {
        color: var(--orange);
        font-weight: var(--font-weight-regular);
        font-size: 2.5rem;
        line-height: 3.4rem;
        margin-right: 0.8rem;
    }

    @media (min-width: 800px) {

    .pagination__controls span {
            font-size: 3.5rem;
            line-height: 4.2rem
    }
        }

.qna-card-container {
  box-shadow: 0px 0px 10px -4px;
  border-radius: 7px;
  width: 100%;
  margin-bottom: 3rem;
  padding: 2rem 2rem;
}

  @media screen and (min-width: 800px) {.qna-card-container {
    padding: 5rem 3rem
}
  }

  .qna-card-container .question-container {
    margin-bottom: 2rem;
  }

  .qna-card-container .question-container h1 {
      font-size: 1.8rem;
      line-height: 2.2rem;
      font-weight: 500;
    }

  .qna-card-container .question-container h1 span {
        color: #31a368;
        font-weight: 900;
      }

  @media screen and (min-width: 800px) {

  .qna-card-container .question-container h1 {
        font-size: 2.2rem;
        line-height: 2.2rem
    }
      }

  .qna-card-container .answer-container h1 {
      font-size: 1.6rem;
      font-weight: 100;
      line-height: 2.2rem;
    }

  .qna-card-container .answer-container h1 span {
        color: var(--orange);
        font-weight: 900;
      }

  @media screen and (min-width: 800px) {

  .qna-card-container .answer-container h1 {
        font-size: 1.9rem;
        line-height: 2.2rem
    }
      }
.radio {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(var(--survey-min-height) - 12rem);
}

    .radio h5 {
        text-transform: capitalize;
    }

    .radio h5.selected {
            color: var(--petrol);
        }

    .radio__wrapper {
        text-align: center;
        width: 100%;
        height: 15rem;
        display: flex;
        align-items: center;
        padding: 0 0.6rem;
    }

    @media (min-width: 800px) {

    .radio__wrapper {
            padding: 0 10rem
    }
        }

    .radio__bar {
        width: 100%;
        position: relative;
    }

    .radio__bar:before {
            content: "";
            position: absolute;
            background-color: var(--gray);
            height: 0.8rem;
            width: 98%;
            top: 50%;
            left: 2px;
            -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
        }

    .radio__choices {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 100%;
        width: 100%;
        cursor: pointer;
        height: 100%;
    }

    .radio__choices__dots-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

    .radio__choices__dots-container--label {
                position: absolute;
                top: 5rem;
                color: var(--dark-gray);
                font-size: 1.4rem;
                line-height: 2rem;
                text-transform: capitalize;
                width: 40%;
            }

    @media (min-width: 800px) {

    .radio__choices__dots-container--label {
                    width: 20rem
            }
                }

    .radio__choices__dots-container--label[data-index="0"] {
                left: -1rem;
            }

    @media (min-width: 800px) {

    .radio__choices__dots-container--label[data-index="0"] {
                    left: auto
            }
                }

    .radio__choices__dots-container--label[data-index="1"],
            .radio__choices__dots-container--label[data-index="3"] {
                right: -.5rem;
            }

    @media (min-width: 800px) {

    .radio__choices__dots-container--label[data-index="1"],
            .radio__choices__dots-container--label[data-index="3"] {
                    right: auto
            }
                }

    .radio__choices--dot {
            border-radius: 50%;
            position: relative;
            z-index: 10;
            width: 2rem;
            height: 2rem;
            background-color: var(--gray);
            border: 2px solid var(--white);
            cursor: pointer;
        }

    .radio__choices--dot--selected {
                -webkit-transform-origin: center;
                        transform-origin: center;
                -webkit-transform: scale(1.6);
                        transform: scale(1.6);
                background-color: var(--orange);
            }

    @media (min-width: 1024px) {

    .radio__choices--dot:hover {
                    border: none;
                    -webkit-transform-origin: center;
                            transform-origin: center;
                    -webkit-transform: scale(1.6);
                            transform: scale(1.6);
                    background-color: var(--orange)
            }
                }

    @media (min-width: 1024px) {

    .radio__choices--dot:hover:after {
                        content: "";
                        width: 2.5rem;
                        height: 2.5rem;
                        border: 2px solid var(--orange);
                        border-radius: 100px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        -webkit-transform: translate(-50%, -50%);
                                transform: translate(-50%, -50%)
                }
                    }

.list {
  min-height: var(--survey-min-height);
  display: flex;
  justify-content: center;
  align-items: center;
}

  .list__header {
    margin-bottom: 3.5rem;
  }

  .list__header_title {
      font-size: 2.6rem;
      line-height: inherit;
      margin-bottom: 1rem;
    }

  .list__header_subtitle {
      font-size: 1.4rem;
      line-height: inherit;
    }

.checklist-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.checklist-item .checklist-outer-square {
    width: 1.8rem;
    height: 1.8rem;
    border: 2px solid var(--orange);
    border-radius: 4px;
    cursor: pointer;
    margin-right: 0.7rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.checklist-item .checklist-outer-square .checklist-inner-square {
      width: 1.2rem;
      height: 1.2rem;
      background-color: var(--orange);
      border-radius: 4px;
    }

.checklist-item span {
    font-size: 1.8rem;
    line-height: 1;
  }
.c-style-graph {
    margin: 4rem auto;

    width: 100%;
    height: 0;
    padding-top: 79%;
    position: relative;
}

    @media (min-width: 800px) {.c-style-graph {
        margin: 6rem auto
}
    }

    @media (min-width: 800px) {.c-style-graph {
        padding-top: 42%
}
    }

    .c-style-graph h3 {
        font-size: var(--h4);
        line-height: 2.7rem;
    }

    @media (min-width: 800px) {

    .c-style-graph h3 {
            font-size: var(--h3);
            line-height: 3.4rem
    }
        }

    .c-style-graph-wrapper {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        cursor: pointer;

        @-webkit-keyframes pulse {
            100% {
                -webkit-transform: scale(2);
                        transform: scale(2);
                opacity: 0;
            }
        }

        @-webkit-keyframes animate-go-big-dot {
            0% {
                -webkit-transform: scale(1);
                        transform: scale(1);
            }

            50% {
                -webkit-transform: scale(2.5);
                        transform: scale(2.5);
            }

            100% {
                -webkit-transform: scale(2);
                        transform: scale(2);
            }
        }

        @-webkit-keyframes animate-go-small-dot {
            0% {
                -webkit-transform: scale(2);
                        transform: scale(2);
            }

            50% {
                -webkit-transform: scale(2.5);
                        transform: scale(2.5);
            }

            100% {
                -webkit-transform: scale(1);
                        transform: scale(1);
            }
        }
    }

    .c-style-graph-wrapper::before {
            content: "";
            position: absolute;
            left: calc(50% - 0.1rem);
            top: 0;
            bottom: 0;
            width: 0.2rem;
            background: #e1e1e2;
        }

    .c-style-graph-wrapper::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: calc(50% - 0.1rem);
            height: 0.2rem;
            background: #e1e1e2;
        }

    .c-style-graph-wrapper .labels {
            position: absolute;
            background-color: var(--white);
            z-index: 1;
            color: var(--mid-gray);
            font-size: var(--p);
            line-height: 2.5rem;
        }

    .c-style-graph-wrapper .labels__introvert,
            .c-style-graph-wrapper .labels__extrovert {
                top: calc(50% - 1.4rem);
            }

    .c-style-graph-wrapper .labels__introvert,
            .c-style-graph-wrapper .labels__facts {
                padding-right: 0.5rem;
            }

    .c-style-graph-wrapper .labels__extrovert,
            .c-style-graph-wrapper .labels__people {
                padding-left: 0.5rem;
            }

    .c-style-graph-wrapper .labels__introvert {
                left: 0;
            }

    .c-style-graph-wrapper .labels__extrovert {
                right: 0;
            }

    .c-style-graph-wrapper .labels__facts,
            .c-style-graph-wrapper .labels__people {
                -webkit-transform-origin: left;
                        transform-origin: left;
                left: calc(50% - 0.1rem);
                -webkit-transform: rotate(-90deg);
                        transform: rotate(-90deg);
            }

    .c-style-graph-wrapper .labels__facts {
                bottom: -2rem;
            }

    .c-style-graph-wrapper .labels__people {
                top: 3.4rem;
            }

    .c-style-graph-wrapper .handleLongExtrovert {
            width: 10rem;
            -webkit-transform: translateX(-2rem);
                    transform: translateX(-2rem);
        }

    @media (min-width: 800px) {

    .c-style-graph-wrapper .handleLongExtrovert {
                width: 12rem;
                -webkit-transform: translateX(-3rem);
                        transform: translateX(-3rem)
        }
            }

    .c-style-graph-wrapper .connecting,
        .c-style-graph-wrapper .influencing,
        .c-style-graph-wrapper .examining,
        .c-style-graph-wrapper .delivering {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: var(--petrol);
            transition: 0.5s;
        }

    .c-style-graph-wrapper .connecting:hover,
        .c-style-graph-wrapper .influencing:hover,
        .c-style-graph-wrapper .examining:hover,
        .c-style-graph-wrapper .delivering:hover {
            -webkit-transform: scale(1.05);
                    transform: scale(1.05);
            transition: 0.5s;
        }

    .c-style-graph-wrapper .influencing,
        .c-style-graph-wrapper .delivering {
            align-items: flex-end;
        }

    .c-style-graph-wrapper .connecting,
        .c-style-graph-wrapper .influencing {
            margin-top: -1rem;
        }

    .c-style-graph-wrapper .examining,
        .c-style-graph-wrapper .delivering {
            justify-content: flex-end;
            margin-bottom: -1rem;
        }

    .c-style-graph-wrapper p.smaller {
            color: var(--orange);
        }

    .c-style-graph-wrapper__dot-boundary {
            position: absolute;
            top: 7rem;
            right: 7rem;
            left: 7rem;
            bottom: 7rem;
            z-index: 2;
        }

    .c-style-graph-wrapper .dot {
            position: absolute;
            border-radius: 50%;
            background: rgba(255, 110, 69, 1);
            -webkit-transform: translate(-0.8rem, -0.8rem);
                    transform: translate(-0.8rem, -0.8rem);
            z-index: 1;
        }

    .c-style-graph-wrapper .dot::before,
            .c-style-graph-wrapper .dot::after {
                content: "";
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: inherit;
                /* background: rgba(255, 110, 69, 1); */
                border-radius: 50%;
                z-index: 0;
                opacity: 0.7;
            }

    .c-style-graph-wrapper .big-dot {
            z-index: 35;
            width: 2.5rem;
            height: 2.5rem;
        }

    .c-style-graph-wrapper .mini-dot {
            width: 1.5rem;
            height: 1.5rem;
            border: 1px solid black;
            -webkit-transform: scale(1);
                    transform: scale(1);
            transition: 1s;
            display: flex;
            align-items: center;
            justify-content: center;
        }

    .c-style-graph-wrapper .go-big-dot {
            -webkit-transform: scale(180%);
                    transform: scale(180%);
        }

    .c-style-graph-wrapper .go-small-dot {
            -webkit-animation-name: animate-go-small-dot;
                    animation-name: animate-go-small-dot;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards;
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
        }

    .c-style-graph-wrapper .animate-dot::before {
                -webkit-animation: pulse 2s ease-out infinite;
                        animation: pulse 2s ease-out infinite;
            }

    .c-style-graph-wrapper .animate-dot::after {
                -webkit-animation: pulse 2s 1s ease-in-out infinite;
                        animation: pulse 2s 1s ease-in-out infinite;
            }

    @keyframes pulse {
            100% {
                -webkit-transform: scale(2);
                        transform: scale(2);
                opacity: 0;
            }
        }

    @keyframes animate-go-big-dot {
            0% {
                -webkit-transform: scale(1);
                        transform: scale(1);
            }

            50% {
                -webkit-transform: scale(2.5);
                        transform: scale(2.5);
            }

            100% {
                -webkit-transform: scale(2);
                        transform: scale(2);
            }
        }

    @keyframes animate-go-small-dot {
            0% {
                -webkit-transform: scale(2);
                        transform: scale(2);
            }

            50% {
                -webkit-transform: scale(2.5);
                        transform: scale(2.5);
            }

            100% {
                -webkit-transform: scale(1);
                        transform: scale(1);
            }
        }

.label-container {
    position: relative;
    width: 80%;
    height: calc(var(--h3) + 20px);
    overflow: hidden;
    border-radius: 50px;
    -webkit-transform: translate(-20px -20px);
            transform: translate(-20px -20px);
}

.labels-mutual {
    position: absolute;
    height: 1200px;
    width: 1200px;
}

.labels-text {
    width: 100%;
    height: 100%;
    color: white;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
}

.labels-text__title {
        font-size: 2rem !important;
        font-weight: 600;
        text-align: center;
    }

.labels-text__icon {
        display: flex;
        align-items: center;
        font-size: 3rem;
        font-weight: 400;
    }

.labels-text__icon svg {
            text-shadow: 0 0 5px white;
        }

@media screen and (max-width: 500px) {
    .label-container {
        width: 90%;
        height: calc(1.5rem + 25px);
    }
            .label-container .labels-text__title {
                font-size: 1.5rem !important;
                width: auto;
            }

            .label-container .labels-text__icon {
                font-size: 2rem;
                padding-right: 0.5rem;
            }
}

.numbers-container {
    width: calc(var(--h3) + 12px);
    height: calc(var(--h3) + 12px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.numbers-container__text {
        font-weight: 900;
        color: white;
        position: absolute;
    }

.labels-video-player {
    display: none;
}

.labels-video-playing {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-container {
    position: relative;
}

.video-container .video-close {
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
    }

.dot-container {
    position: absolute;
}

.mini-dot-container {
    width: 20px;
    height: 20px;
    position: absolute;
}

.mini-dot-container .dot-user-number {
        z-index: 30;
        color: white;
        font-size: 1rem;
        font-weight: 600;
    }

.dot-here-text {
    position: relative;
    top: 2.7rem;
    left: -1.5rem;
    width: 5rem;
    font-weight: bold;
    z-index: 2;
}

@media screen and (min-width: 800px) {

.dot-here-text {
        width: 6rem
}
    }

.hide {
    display: none !important;
}

.show {
    display: block;
}
.pwc-results-container {
  margin: 2rem 0 5rem;
}
  @media screen and (min-width: 800px) {.pwc-results-container {
    margin: 4rem 0 0
}
  }
  .pwc-results-container h1 {
    line-height: normal;
    font-size: 2rem;
    margin: 1rem 0 2rem 0;
  }
  .pwc-results-container .c-style-graph {
    margin: 8rem auto 4rem auto;
  }
  @media screen and (min-width: 800px) {
  .pwc-results-container .c-style-graph {
      margin: 10rem auto 7rem auto
  }

      .pwc-results-container .c-style-graph .labels__facts {
        bottom: -6rem;
      }
    }
  .pwc-results-container .c-style-graph .dot-here-text {
      font-size: 1.5rem;
      line-height: normal;
    }
  .pwc-results-container .video-intro-text {
    padding: 1rem 0 2rem;
  }
  .pwc-results-container .video-container {
    text-align: center;
  }
.overWriteSurvey {
  padding-bottom: 12rem !important;
  /* width: 100vw !important; */
  -webkit-transform: translateX(calc(-1 * var(--gutter))) !important;
          transform: translateX(calc(-1 * var(--gutter))) !important;
  margin: 0 auto !important;
  line-height: normal !important;
}

.firstPage-container {
  position: relative;
}

.firstPage-container .page-wrapper h1 {
      font-size: 2.2rem;
      font-weight: 600;
      margin: 2.5rem 0 .8rem;
      line-height: normal;
    }

.firstPage-container .page-wrapper p {
      font-size: var(--p-larger);
      line-height: 2.7rem;
    }

.firstPage-container .page-wrapper .instructions-container .text-container {
        margin: 4rem auto;
      }

.firstPage-container .page-wrapper .instructions-container .text-container p {
          margin-top: 3rem;
        }

.firstPage-container .page-wrapper .cards-container {
      min-height: 4rem;
      padding: 0 1rem;
    }

.firstPage-container .page-wrapper .blue {
      border: 2px solid #07586B;
    }

.firstPage-container .page-wrapper .blue .blueBtn {
        display: none;
      }

.firstPage-container .page-wrapper .yellow {
      border: 2px solid #FEC378;
    }

.firstPage-container .page-wrapper .yellow .yellowBtn {
        display: none;
      }

.firstPage-container .page-wrapper .orange {
      border: 2px solid #FF6E45;
    }

.firstPage-container .page-wrapper .orange .orangeBtn {
        display: none;
      }

.firstPage-container .page-wrapper .questions-container {
      margin: 3rem auto;
    }

@media screen and (min-width: 800px) {

.firstPage-container .page-wrapper .questions-container {
        margin-top: 6.5rem
    }
      }

.firstPage-container .page-wrapper .si-page-width {
      max-width: var(--wide-page-width);
      margin-left: auto;
      margin-right: auto;
    }


.card-component-container {
  width: 100%;
  padding: 2rem 2rem;
  border-radius: 7px;
  box-shadow: 0px 0px 10px -4px;
  margin: 2rem 0;
}


@media screen and (min-width: 800px) {


.card-component-container {
    width: 80%;
    margin: 2rem auto
}
  }


.card-component-container .question-text-container {
    display: flex;
    align-items: center;
    margin: 1rem auto 2rem;
  }


.card-component-container .question-text-container h1 {
      display: flex;
      align-items: center;
      margin: 0;
      font-size: 1.5rem;
      font-weight: 400;
    }


@media screen and (min-width: 800px) {


.card-component-container .question-text-container h1 {
        font-size: 1.8rem
    }
      }


.card-component-container .question-text-container .questionRanking {
      font-size: 1.7rem;
      padding: .3rem 1rem;
      border-radius: 50%;
      border: 2px solid #FF6E45;
      margin: 0 1rem;
      word-break: normal;
    }


@media screen and (min-width: 500px) {


.card-component-container .question-text-container .questionRanking {
        margin: 0 1.5rem
    }
      }


.card-component-container .more-text-container {
    border-top: 2px solid var(--light-gray);
    margin: 3rem 0;
    position: relative;
  }


.card-component-container .more-text-container .text-controls {
      position: absolute;
      top: -1rem;
      right: 0;
      width: 40%;
      background: white;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }


@media screen and (min-width: 500px) {


.card-component-container .more-text-container .text-controls {
        width: 20%
    }
      }


.card-component-container .more-text-container .text-controls .showOrHide {
        color: #07586B;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        margin: 0 .5rem;
      }


.card-component-container .more-text-container .text-controls .chevron-default {
        font-size: 2rem;
        transition: .3s;
        display: flex;
        align-items: center;
      }


.card-component-container .more-text-container .text-card {
      padding: 2rem 0;
      border-bottom: 2px solid var(--light-gray);
    }


.card-component-container .more-text-container .text-card ul {
        list-style-type: disc;
        padding: 0 1.5rem 0;
      }


@media screen and (min-width: 800px) {


.card-component-container .more-text-container .text-card ul {
          padding: 0 4rem 0
      }
        }


.card-component-container .more-text-container .text-card ul li {
          font-size: 1.4rem;
          margin: 1rem auto;
        }


@media screen and (min-width: 800px) {


.card-component-container .more-text-container .text-card ul li {
            font-size: 1.6rem
        }
          }


.card-component-container .buttons-container {
    display: flex;
    justify-content: center;
  }


.card-component-container .buttons-container button {

      width: 30%;
      margin: 0 .2rem;
      padding: 1rem .2rem;
      border-radius: 2rem;
      background-color: white;
      font-size: 1rem;
      font-weight: 600;
      border: 1px solid;
    }


@media screen and (min-width: 500px) {


.card-component-container .buttons-container button {
        width: 25%;
        padding: 1.4rem .5rem;
        margin: 0 1rem;
        font-size: 1.3rem
    }
      }


@media screen and (min-width: 800px) {


.card-component-container .buttons-container button {
        padding: 1.7rem 1.2rem
    }
      }


.card-component-container .buttons-container .blueBtn {
      color: #07586B;
      border-color: #07586B;
    }


.card-component-container .buttons-container .blueBtn:hover {
        color: white;
        background: #07586B;
      }


.card-component-container .buttons-container .yellowBtn {
      color: #FEC378;
      border-color: #FEC378;
    }


.card-component-container .buttons-container .yellowBtn:hover {
        color: white;
        background: #FEC378;
      }


.card-component-container .buttons-container .orangeBtn {
      color: #FF6E45;
      border-color: #FF6E45;
    }


.card-component-container .buttons-container .orangeBtn:hover {
        color: white;
        background: #FF6E45;
      }


.card-component-container .buttons-container .arrow {
      font-size: 2.3rem;
      width: auto;
      height: auto;
      padding: 0;
    }


.card-component-container .buttons-container .arrow svg {
        color: #07586B;
        border: none;
        border-radius: 50%;
      }


@media screen and (min-width: 800px) {


.card-component-container .buttons-container .arrow svg {
          font-size: 3rem
      }
        }


.card-component-container .buttons-container .arrow svg:hover {
          background-color: #07586B;
          color: white;
        }


.card-component-container .buttons-container .arrow svg:active {
          opacity: .6;
        }


.card-component-container .disabledBtn {
    color: gray !important;
    border-color: gray !important;
    opacity: .5 !important;
  }


.card-component-container .disabledBtn:hover {
      background-color: transparent !important;
      cursor: not-allowed;
    }


.card-component-container .disabledBtn:active {
      cursor: not-allowed !important
    }


.card-component-container .d-none {
    display: none;
  }


.card-component-container .d-block {
    display: block;
  }


.card-component-container .rotate-up {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    transition: .3s;
  }
@media screen and (min-width: 800px) {.SI_SecondPage-container {
    max-width: var(--wide-page-width);
    margin: 0 auto
}
  }
  .SI_SecondPage-container .page-wrapper .right-answers-container {
      margin: 4rem auto;
    }
  @media screen and (min-width: 800px) {
  .SI_SecondPage-container .page-wrapper .right-answers-container {
        margin: 6rem auto
    }
      }
  .SI_SecondPage-container .page-wrapper .right-answers-container p {
        font-size: inherit;
        color: black;
      }
  .SI_SecondPage-container .page-wrapper .right-answers-container p span {
          color: #31a368;
          font-weight: 600;
        }
  .SI_SecondPage-container .page-wrapper .right-answers-container h1 {
        font-size: 2rem;
        font-weight: 600;
        color: black;
        line-height: normal;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }

.answer-description-container {
  box-shadow: 0px 0px 10px -4px;
  border-radius: 7px;
  width: 100%;
  margin-bottom: 3rem;
  padding: 2rem 2rem;
}

@media screen and (min-width: 800px) {

.answer-description-container {
    padding: 5rem 3rem
}
  }

.answer-description-container .right-answer-container {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

.answer-description-container .right-answer-container div {
      display: flex;
    }

.answer-description-container .right-answer-container h1 {
      font-size: 1.7rem;
      color: #31a368;
      line-height: normal;
    }

.answer-description-container .right-answer-container svg {
      font-size: 3rem;
      color: #31a368;
      margin-right: 1rem;
    }

.answer-description-container .wrong-answer-container {
    display: flex;
    align-items: center;
  }

.answer-description-container .wrong-answer-container div {
      display: flex;
    }

.answer-description-container .wrong-answer-container h1 {
      font-size: 1.7rem;
      color: #c92424;
      line-height: normal;
    }

.answer-description-container .wrong-answer-container svg {
      font-size: 3rem;
      color: #c92424;
      margin-right: 1rem;
    }

.answer-description-container p {
    margin-top: 2rem;
    line-height: normal;
    font-weight: 400;
  }

.answer-description-container .references-container h1 {
      font-size: 1.7rem;
      font-weight: 600;
      line-height: 3rem;
      margin-top: 1rem;
    }

.answer-description-container .references-container ul {
      list-style-type: disc;
      padding-left: 1rem;
    }

.answer-description-container .references-container ul li {
        font-size: 1.2rem;
      }
@media screen and (min-width: 800px) {.qna-container {
    max-width: var(--wide-page-width);
    margin: 0 auto
}
  }

  .qna-container .qna-text-container {
    margin: 4rem auto;
  }

  @media screen and (min-width: 800px) {

  .qna-container .qna-text-container {
      margin: 6rem auto
  }
    }

  .qna-container h1 {
    font-size: 2rem;
    font-weight: 600;
    color: black;
    line-height: normal;
    margin-bottom: 2rem;
  }
.trueOrFalse {
  height: var(--survey-min-height);
  display: flex;
  align-items: center;
}

  .trueOrFalse div .header__title {
        font-size: 2.2rem;
        line-height: 1.1;
      }

  .trueOrFalse div .header__subtitle {
        font-style: italic;
        padding: 1rem 0;
      }

  .trueOrFalse div .buttons-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 3rem 0;
    }

  .trueOrFalse div .buttons-container .btn-container {
        border: 2px solid white;
        padding: 3px 3px;
        margin: 0 0.5rem;
      }

  .trueOrFalse div .buttons-container .btn {
        min-width: 5rem;
        height: 3rem;
        cursor: pointer;
        font-weight: 400;
        border-radius: 4px;

        border: 1px solid var(--dark-gray);
        color: var(--dark-gray);
        background-color: white;


      }

  .trueOrFalse div .buttons-container .btn-hover:hover {
        color: white;
        background-color: var(--dark-gray);
      }

  .trueOrFalse div .buttons-container .correct {
        border: 1px solid #1ab394;
        color: white;
        background-color: #1ab394;
      }

  .trueOrFalse div .buttons-container .incorrect {
        border: 1px solid #d93f02;
        color: white;
        background-color: #d93f02;
      }

  .trueOrFalse div .buttons-container .isUserPick {
        border: 2px solid;
        border-radius: 4px;
      }

  .trueOrFalse div .reveal-hid {
      visibility: hidden;
    }

  .trueOrFalse div .reveal {
      -webkit-animation-name: fadeIn;
              animation-name: fadeIn;
      -webkit-animation-duration: 0.5s;
              animation-duration: 0.5s;
      -webkit-animation-iteration-count: 1;
              animation-iteration-count: 1;
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }

@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
:root {
  --primary: #FFCE00;
  --secondary: #FE4880;
  --dark: #212121;
  --light: #F3F3F3;
}

.card {
  width: 560px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  display: flex;
}

@media screen and (max-width: 450px) {

.card {
    width: 300px
}
  }

.card .card-inner {
    width: 100%;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    cursor: pointer;
    position: relative;
  }

.card .card-inner .card-face {
      position: absolute;
      width: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow: hidden;
      border-radius: 16px;
      box-shadow: 0px 3px 18px 3px rgba(0, 0, 0, 0.2);
      padding: 20px;
      display: flex;
      align-items: center;
    }

.card .card-inner .face-front {
      background-image: linear-gradient(114deg, rgba(255, 158, 0, 1) 0%, rgba(254, 72, 128, 1) 100%);
      display: flex;
      align-items: center;
      justify-content: center;
    }

.card .card-inner .face-front .face-front-children {
        color: white;
        text-align: center;
      }

.card .card-inner .face-front .face-front-children h1 {
          line-height: inherit;
          font-size: 2.2rem;
          color: white;
        }

.card .card-inner .face-front .face-front-children span {
          font-size: 3rem;
          display: inline-flex;
          padding: 2px 10px;
          margin-top: 20px;
          border: 2px solid;
          border-radius: 50%;
          box-shadow: 0 0 20px -3px;
          transition: .3s;
        }

.card .card-inner .face-front .face-front-children span:hover {
            box-shadow: 0 0 100px 50px;
          }

.card .card-inner .face-back {
      background-color: #F3F3F3;
      background-color: #F3F3F3;
      background-color: var(--light);
      -webkit-transform: rotateY(180deg);
              transform: rotateY(180deg);
    }

.card .isFlipped {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

.height100 {
  height: 100%;
}

.card-face-children-container {
  width: 100%;
}

.card-face-children-container .flip-card-ul {
    padding: 2rem;
    list-style-type: disc;
  }

.card-face-children-container .flip-card-ul li {
      margin: 5px 0;
      color: var(--black);
      font-size: 1.6rem;
      line-height: 1.3;
    }

.card-face-children-container h2 {
    color: var(--black);
    line-height: 1;
    font-size: 2.2rem;
  }
.cards-container {
  padding-bottom: 5rem;
}

  .cards-container .header,
  .cards-container .conclusion {
    margin: 4rem auto 0 auto;
    width: 560px;
  }

  .cards-container .header h1, .cards-container .conclusion h1 {
      font-size: 2.2rem;
      line-height: inherit;
      margin-bottom: 2rem;
    }

  .cards-container .header h2, .cards-container .conclusion h2 {
      font-size: 2rem;
      line-height: inherit;
      opacity: 0.8;
    }

  .cards-container .cards-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cards-container .conclusion {
    margin-top: 2rem;
  }

  @media screen and (max-width: 450px) {

    .cards-container .header,
    .cards-container .conclusion {
      width: 300px;
    }

      .cards-container .header h1, .cards-container .conclusion h1 {
        font-size: 2rem;
      }

      .cards-container .header h2, .cards-container .conclusion h2 {
        font-size: 1.8rem;
      }
  }
.completed-science-btns-container {
  width: 100%;
  text-align: center;
}
  
  .completed-science-btns-container button {
    margin: .5rem 0;
  }
  
  @media screen and (min-width: 800px) {
  
  .completed-science-btns-container button {
      margin: 0 .5rem
  }
    }


.generic-content .page-wrapper {
        padding-top: 1.8rem;
    }
    @media (min-width: 800px) {.generic-content {
        max-width: var(--narrow-page-width);

        margin: 0 auto
}
    }
    @media (min-width: 800px) {
    .generic-content h2 {
            font-size: 5.5rem;
            line-height: 6rem
    }
        }
    .generic-content h2,
    .generic-content h3,
    .generic-content h4 {
        margin-bottom: 2rem;
    }
    @media (min-width: 800px) {
    .generic-content h2,
    .generic-content h3,
    .generic-content h4 {
            margin-bottom: 2.4rem
    }
        }
    @media (min-width: 800px) {
    .generic-content h3 {
            font-size: 3.5rem;
            line-height: 4.2rem
    }
        }
    @media (min-width: 800px) {
    .generic-content h4 {
            font-size: 2.8rem;
            line-height: 3.4rem
    }
        }
    .generic-content p {
        font-size: var(--p-smaller);
        line-height: 1.8rem;
        color: var(--dark-gray);
    }
    @media (min-width: 800px) {
    .generic-content p {
            font-size: var(--p-larger);
            line-height: 3.2rem
    }
        }
    .generic-content p + h1,
    .generic-content p + h2,
    .generic-content p + h3,
    .generic-content p + h4,
    .generic-content ul + h3,
    .generic-content ol + h4,
    .generic-content ul + h4 {
        margin-top: 4.4rem;
    }
    @media (min-width: 1024px) {
    .generic-content p + h1,
    .generic-content p + h2,
    .generic-content p + h3,
    .generic-content p + h4,
    .generic-content ul + h3,
    .generic-content ol + h4,
    .generic-content ul + h4 {
            margin-top: 4.8rem
    }
        }
    .generic-content ul + p,
    .generic-content p + ul {
        margin-top: 2rem;
    }
    .generic-content p + li,
    .generic-content li + p {
        margin-top: 2rem;
    }
    @media (min-width: 1024px) {
    .generic-content p + li,
    .generic-content li + p {
            margin-top: 2.4rem
    }
        }
    .generic-content li {
        color: var(--dark-gray);
        font-weight: var(--font-weight-light);
        margin-bottom: 1.2rem;
        font-size: var(--p-smaller);
        margin-top: 1.2rem;
    }
    @media (min-width: 1024px) {
    .generic-content li {
            font-size: 2.3rem;
            line-height: 3.2rem;
            margin-bottom: 1.6rem;
            margin-top: 1.6rem
    }
        }
    .generic-content ol,
    .generic-content ul,
    .generic-content ul li {
        margin-left: 1.2rem;
    }
    @media (min-width: 1024px) {
    .generic-content ol,
    .generic-content ul,
    .generic-content ul li {
            margin-left: 1.6rem
    }
        }
    .generic-content ol ol,
    .generic-content ul ul {
        margin-left: 2rem;
    }
    @media (min-width: 1024px) {
    .generic-content ol ol,
    .generic-content ul ul {
            margin-left: 2.4rem
    }
        }
    .generic-content ul {
        list-style-type: disc;
    }
    .generic-content--about-page p {
            font-size: var(--p);
            line-height: 2.7rem;
        }
    @media (min-width: 800px) {
    .generic-content--about-page p {
                font-size: 2.3rem;
                line-height: 1.4
        }
            }
    .generic-content--about-page li {
            font-size: var(--p);
            line-height: 2.7rem;
        }
    @media (min-width: 800px) {
    .generic-content--about-page li {
                font-size: 2.3rem;
                line-height: 3.2rem
        }
            }

.modal {
    background-color: rgba(255, 255, 255, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1002;
}

    .modal--cstyle .modal__panel .page-wrapper {
                padding-top: 0;
            }

    .modal--iframe .modal__panel {
            height: 100%;
        }

    .modal__panel::-webkit-scrollbar {
            display: none;
        }

    .modal__panel {
        -ms-overflow-style: none;
        scrollbar-width: none;
        background-color: var(--white);
        padding-bottom: 2rem;
        width: 100%;
        max-height: 80%;
        overflow-y: scroll;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 32px 32px 0px 0px;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
}

    /* Firefox */

    @media (min-width: 800px) {

    .modal__panel {
            top: 50%;
            left: 50%;
            bottom: auto;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            width: 58.4rem;
            border-radius: 24px
    }
        }

    /* IE and Edge */

    .modal__panel__heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: var(--white);
            padding: 3.6rem 2rem 2rem 2rem;
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            left: 0;
            z-index: 1;
        }

    .modal__panel__heading .subtitle {
                color: var(--petrol);
            }

    .modal__panel__heading h4 {
                color: var(--petrol);
            }

    .modal__panel__heading__close {
                cursor: pointer;
            }

    @media (min-width: 800px) {

    .modal__panel__heading {
                margin-left: var(--gutter);
                margin-right: var(--gutter);
                padding-left: 0;
                padding-right: 0;
                border-bottom: 0.2rem solid var(--light-gray)
        }
            }

    /* Hide scrollbar for IE, Edge and Firefox */

    .modal__panel__gradient {
            width: 100%;
            height: 12rem;
            position: -webkit-sticky;
            position: sticky;
            bottom: 0;
            left: 0;
            background: var(--gradient-white);
        }

    .modal__panel iframe {
            width: 100%;
            height: 100%;
        }

    @media (min-width: 800px) {

    .modal__panel iframe {
                padding: 0 1rem
        }
            }

    .modal .generic-text-page .page-wrapper,
    .modal .generic-content .page-wrapper {
        padding-top: 1.2rem;
    }

    @media (min-width: 800px) {

    .modal .generic-text-page .page-wrapper,
    .modal .generic-content .page-wrapper {
            padding-top: 2.4rem
    }
        }

.level-info-modal {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
}

    .level-info-modal svg {
        width: 100%;
        height: auto;
    }

    .level-info-modal__text {
        font-size: var(--h4);
        line-height: 2.7rem;
        color: var(--dark-gray);
        padding: 0 calc(var(--gutter) * 2);
    }

    .level-info-modal__text strong {
            color: var(--orange);
            font-weight: normal;
        }

    .level-info-modal__badge-wrapper {
        position: relative;
        z-index: 2;
        flex: 0 0 12rem;
        width: 12rem;
        height: 12rem;
        transition: background-color 0.7s;
    }

    .level-info-modal__badge-name {
        font-size: var(--h3);
        line-height: 3.4rem;
        margin-top: 2.2rem;
        color: var(--orange);
        margin-bottom: 4.4rem;
    }

    .level-info-modal .button-primary {
        width: calc(100% - (var(--gutter) * 4));
        margin-top: 4.4rem;
    }

.invite-modal-conatiner {
  padding: 2rem 2rem;
}

  .invite-modal-conatiner .closeModal {
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
  }

  .invite-modal-conatiner .closeModal svg {
      cursor: pointer;
      height: 2rem;
      width: 2rem;
    }

  .invite-modal-conatiner .icon-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .invite-modal-conatiner .icon-container svg {
      width: 7rem;
      height: 7rem;
    }

  .invite-modal-conatiner .icon-container svg .cls-1 {
        fill: #ff6e45;
      }

  .invite-modal-conatiner .email-text-container h1 {
      font-size: 1.8rem;
      line-height: normal;
      padding: 2rem;
      text-align: center;
    }

  .invite-modal-conatiner .email-text-container button {
      display: flex;
      align-items: center;
      background-color: #07586b;
      color: white;
      border-radius: 20px;
      border: none;
      margin: 0 auto;
      padding: 1rem 2rem;
    }

  .invite-modal-conatiner .email-text-container button:hover {
        opacity: .6;
      }

  .invite-modal-conatiner .email-text-container button span {
        font-size: 1.8rem;
        margin-right: 1rem;
      }

  .invite-modal-conatiner .email-text-container button svg {
        height: 2rem;
        width: 2rem;
      }

  .invite-modal-conatiner .email-text-container button svg .cls-1 {
          fill: white !important;
        }

  .invite-modal-conatiner .email-text-container .input-container {
      position: relative;
      border: 1px solid lightgray;
      border-radius: 2rem;
      padding: .2rem .2rem .2rem 1rem;
      display: flex;
    }

  .invite-modal-conatiner .email-text-container .input-container input {
        border: none;
        padding: 0;
        margin: 0;
        border-radius:
      }

  .invite-modal-conatiner .email-text-container .input-container svg {
        width: 3.5rem;
        height: 3rem;
        background-color: #07586b;
        border-radius: 50%;
        padding: .4rem;
      }

  .invite-modal-conatiner .email-text-container .input-container svg:hover {
          opacity: .6;
          cursor: pointer;
        }

  .invite-modal-conatiner .email-text-container .input-container svg .cls-1 {
          fill: white !important;
        }

.loading-container {
  text-align: center;
  height: 100%;
  width: 100%;
}

.loading-div {
  width: 60px;
  height: 60px;
  margin: 10px auto;
  border-radius: 50%;
  border-top: 4px solid #ff6e45;
  border-right: 4px solid #ff6e45;
  border-bottom: 4px solid #ff6e45;
  border-left: 4px solid transparent;
  -webkit-animation: spinner 1s ease-in-out infinite;
          animation: spinner 1s ease-in-out infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.send-my {
  padding: var(--gutter);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 295px;
}


.send-my__results {
    padding: 3rem 0;
  }


.send-my__results__close {
      display: inline-block;
      position: absolute;
      right: 3rem;
      top: 3rem;
      cursor: pointer;
      transition: .2s;
    }


.send-my__results__close:hover {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
      }


.send-my__results__attachment {
      width: 5rem;
      height: 5rem;
      display: block;
      text-align: center;
      margin: 2rem auto;
    }


.send-my__results__attachment .cls-1 {
        fill: #ff6e45;
      }


.send-my__results__text {
      margin: 2rem 0;
      padding: 0 3rem;
    }


.send-my__results__text__primary {
        font-size: 2.2rem;
      }


.send-my__results__email-box {
      position: relative;
      border: 1px solid lightgray;
      border-radius: 2rem;
      padding: .2rem .2rem .2rem 1rem;
      display: flex;
    }


.send-my__results__email-box input {
        border: none;
        padding: 0;
        margin: 0;
        outline: none;
      }


.send-my__results__email-box svg {
        width: 3.5rem;
        height: 3rem;
        background-color: #07586b;
        border-radius: 50%;
        padding: .4rem;
      }


.send-my__results__email-box svg:hover {
          opacity: .6;
          cursor: pointer;
        }


.send-my__results__email-box svg .cls-1 {
          fill: white !important;
        }
.level-up-modal {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
}

    .level-up-modal svg {
        width: 100%;
        height: auto;
    }

    .level-up-modal__text {
        font-size: var(--h4);
        line-height: 2.7rem;
        color: var(--dark-gray);
        padding: 0 calc(var(--gutter) * 2);
    }

    .level-up-modal__badge-wrapper {
        position: relative;
        z-index: 2;
        flex: 0 0 12rem;
        width: 12rem;
        height: 12rem;
        transition: background-color 0.7s;
    }

    .level-up-modal__badge-name {
        font-size: var(--h3);
        line-height: 3.4rem;
        margin-top: 2.2rem;
        color: var(--orange);
    }

    @media (min-width: 800px) {

    .level-up-modal__badge-name {
            margin-bottom: 4.4rem
    }
        }

    .level-up-modal__close {
        position: absolute;
        cursor: pointer;
        right: 1.7rem;
        top: 3.5rem;
    }

    @media (min-width: 800px) {

    .level-up-modal__close {
            display: none
    }
        }

    .level-up-modal .button-primary {
        width: calc(100% - (var(--gutter) * 4));
        margin-top: 4.4rem;
        display: none;
    }

    @media (min-width: 800px) {

    .level-up-modal .button-primary {
            display: block
    }
        }

.message--badge {
    padding: 4rem 0;
}

.message--badge .page-wrapper {
        margin-top: 2.2rem;
    }

@media (min-width: 800px) {

.message--badge .page-wrapper {
            margin-top: 0
    }
        }

.message h2 {
        margin-bottom: 2.8rem;
        color: var(--petrol);
    }
    .message p {
        text-align: center;
        color: var(--dark-gray);
        margin-bottom: 3.8rem;
    }
    .message__icon {
        width: 10rem;
        height: 10rem;
        background-color: gray;
        margin-bottom: 1.4rem;
    }
    .message .page-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .message__cta {
        display: flex;
        width: 100%;
        margin-top: 4.4rem;
        justify-content: space-between;
    }
    .message__cta .button-primary {
            width: calc(100% - (var(--gutter) * 4));
            margin: 0 auto;
        }
    @media (min-width: 800px) {
    .message__cta .button-primary {
                width: 17.4rem
        }
            }
    .message__cta--multiple .button-primary {
                flex: 1 1;
                margin-left: 1.5rem;
            }
    .message__cta--multiple .button-secondary {
                flex: 1 1;
            }
    .message__video-wrapper {
        height: 50rem;
    }
    .message__video-wrapper iframe {
            width: 100%;
            height: 100%;
        }

.certificate-view .page-wrapper {
        padding-top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .certificate-view__background {
        background-size: cover;
        background-repeat: no-repeat;
        padding: 1rem;
        width: 100%;
    }
    @media (min-width: 800px) {
    .certificate-view__background {
            width: 35rem
    }
        }
    .certificate-view__content {
        background-color: var(--white);
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2.4rem 0;
    }
    .certificate-view__content__logo {
            width: 6.1rem;
            height: 3.3rem;
            margin-bottom: 6.4rem;
        }
    .certificate-view__content__logo svg {
                width: 100%;
                height: 100%;
            }
    .certificate-view__content__logo svg path {
                    fill: var(--orange);
                }
    .certificate-view__content h4 {
            margin-bottom: 5.6rem;
        }
    .certificate-view__content h4:first-of-type {
            margin-bottom: 3.2rem;
        }
    .certificate-view__content__ribbon {
            margin-bottom: 3.8rem;
        }
    .certificate-view__content p.subtitle {
                margin-bottom: 0.4rem;
            }
    .certificate-view__content p.subtitle:first-of-type {
                    margin-bottom: 0.7rem;
                }
    .certificate-view__content p + p {
            margin-top: 0;
        }
    .certificate-view button {
        margin-top: 2rem;
        width: calc(100% - (var(--gutter) * 4));
    }

.profile {
    margin: 0 auto;

    padding-bottom: 10rem;
}

    @media (min-width: 800px) {.profile {
        max-width: var(--narrow-page-width)
}
    }

    @media (min-width: 800px) {

    .profile .action-panel {
            display: none
    }
        }

    .profile p.subtitle {
            margin-bottom: 1.2rem;
        }

    .profile p.larger {
            color: var(--dark-gray);
            margin-bottom: 1.2rem;
        }

    @media (min-width: 800px) {

    .profile p.larger {
                font-size: var(--h4);
                line-height: 1.4
        }
            }

    .profile p.larger.cta {
                color: var(--orange);
                text-decoration: underline;
                margin-top: 2rem;
                cursor: pointer;
            }

    .profile__info h2 {
            margin-bottom: 0.4rem;
        }

    @media (min-width: 800px) {

    .profile__info h2 {
                font-size: var(--h1-big);
                line-height: 1.4
        }
            }

    .profile__info h4 {
            color: var(--dark-gray);
        }

    @media (min-width: 800px) {

    .profile__info h4 {
                font-size: var(--h2);
                line-height: 1.4;
                margin-bottom: 4rem
        }
            }

    .profile__c-style h3 {
            margin-bottom: 1.3rem;
        }

    @media (min-width: 800px) {

    .profile__c-style h3 {
                font-size: var(--h2);
                line-height: 1.4
        }
            }

    .profile hr {
        width: 100%;
        max-width: 100%;
        margin: 2.8rem 0;
    }

    @media (min-width: 800px) {

    .profile hr {
            margin: 4rem 0;
            border-width: 0.4rem
    }
        }

    .profile__certificates span {
            color: var(--orange);
        }

    .profile .result_quadrant p {
            color: var(--dark-gray);
        }

    .profile .result_quadrant__header {
        height: auto;
        margin-left: 0;
        margin-right: 0;
    }

    .profile .result_quadrant__background {
        display: none;
    }

    .profile .result_quadrant__copy {
        padding: 0;
        position: static;
        color: var(--black);
    }

    .profile .result_quadrant__copy p {
            display: none;
        }

    .profile .result_video {
        display: none !important;
    }

    .profile .phone-view-video-container {
        display: none !important;
    }

.action-panel {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--white);
    border-radius: 3.2rem 3.2rem 0 0;
    padding: 2rem;
    -webkit-filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
    transition: opacity 0.5s, -webkit-transform 0.5s;
    transition: transform 0.5s, opacity 0.5s;
    transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
    opacity: 0;
    overflow: hidden;
    z-index: 1;
}

    @media (min-width: 800px) {.action-panel {
        left: 50%;
        bottom: 4rem;
        width: 58rem;
        border-radius: 3.2rem;
        -webkit-transform: translate3d(-50%, 100%, 0);
                transform: translate3d(-50%, 100%, 0);
        text-align: center
}
    }

    .action-panel--visible {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    @media (min-width: 800px) {

    .action-panel--visible {
            -webkit-transform: translate3d(-50%, 0, 0);
                    transform: translate3d(-50%, 0, 0)
    }
        }

    .action-panel--player-is-visible {
        padding-bottom: 12rem;
    }

    .action-panel .podcast-player__close-button {
        position: absolute;
        top: 0.5rem;
        right: 2rem;
        cursor: pointer;
        padding: 0.5rem 0 0 0.5rem;
    }

    .action-panel .podcast-player__close-button svg path {
                fill: var(--dark-gray);
            }

    .action-panel .podcast-player__minify-button {
        position: absolute;
        top: 0.5rem;
        left: 2rem;
        cursor: pointer;
        padding: 0.5rem 0 0 0.5rem;
    }

    .action-panel .podcast-player__minify-button svg path {
                fill: var(--dark-gray);
            }

    .action-panel--for-player--expanded {
            padding: 0;
            z-index: 9999;

            border-radius: 3.2rem;
            left: 2rem;
            right: 2rem;
            top: 2rem;
            bottom: 2rem;
            -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);
            overflow: visible; /* explicitly set overflow visible for Safari */
            -webkit-mask-image: radial-gradient(white, black);
                    mask-image: radial-gradient(white, black);
        }

    @media (min-width: 800px) {

    .action-panel--for-player--expanded {
                left: 50%;
                right: 0;
                top: 2rem;
                bottom: 2rem;
                -webkit-transform: translate3d(-50%, 0, 0);
                        transform: translate3d(-50%, 0, 0)
        }
            }

    .action-panel--for-player--expanded .podcast-player__close-button {
                top: 3.4rem;
                right: 3.4rem;
            }

    .action-panel--for-player--expanded .podcast-player__close-button svg path {
                        fill: white;
                    }

    .action-panel--for-player--expanded .podcast-player__minify-button {
                top: 3.4rem;
                left: 3.4rem;
            }

    .action-panel--for-player--expanded .podcast-player__minify-button svg path {
                        fill: transparent;
                    }

    .action-panel--for-player--wrapper {
            width: 100%;
            height: 100%;
            background: var(--black);
        }

    .action-panel .results_button_panel {
        display: flex;
        flex-direction: row;
    }

    .action-panel .results_button_panel button + button {
            margin-left: 2rem;
        }

.level-indicator {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
}

    .level-indicator svg {
        width: 100%;
        height: auto;
    }

    .level-indicator__badge-wrapper {
        position: relative;
        z-index: 2;
        flex: 0 0 6rem;
        width: 6rem;
        height: 6rem;
        margin: -0.1em;
        transition: background-color 0.7s;
    }

    @media (min-width: 800px) {

    .level-indicator__badge-wrapper {
            height: 12rem;
            width: 12rem;
            flex: 0 0 12rem
    }
        }

    .level-indicator__info-section {
        padding-left: 2rem;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-self: flex-end;
    }

    @media (min-width: 800px) {

    .level-indicator__info-section {
            padding-left: 4rem;
            align-self: center
    }
        }

    .level-indicator__label {
        font-size: 1.3rem;
        line-height: 1.6rem;
        text-transform: uppercase;
        color: var(--orange);
    }

    @media (min-width: 800px) {

    .level-indicator__label {
            font-size: var(--h5);
            line-height: 1.4
    }
        }

    .level-indicator__name {
        font-size: var(--h3);
        line-height: 3.4rem;
        margin-top: 0.4rem;
    }

    @media (min-width: 800px) {

    .level-indicator__name {
            font-size: var(--h2);
            line-height: 1.4
    }
        }

.page-wrapper {
    padding-top: 20px;
    padding-bottom: 0;
}

    .page-wrapper .static-text {
        font-size: var(--p-larger);
        margin-top: 2rem;
    }
@media screen and (min-width: 700px) {
    .page-wrapper {
        overflow: hidden;
    }
}

@media (min-width: 800px) {

.c-style-results {
        max-width: var(--wide-page-width);
        margin: 0 auto
}
            .c-style-results .action-panel button {
                margin: 0 auto;
            }
    }

.c-style-results p,
    .c-style-results h2 {
        margin-bottom: 1.2rem;
    }

.c-style-results h2 {
        margin-top: 1.2rem;
    }

@media (min-width: 800px) {

.c-style-results h2 {
            padding-bottom: 3.2rem;
            margin-bottom: 4.8rem;
            border-bottom: 4px solid var(--light-gray)
    }
        }

.c-style-results h2 .button-primary--remake {
            display: none;
        }

@media (min-width: 800px) {

.c-style-results h2 .button-primary--remake {
                display: inline-block;
                float: right
        }
            }

.c-style-results a {
        text-transform: uppercase;
        font-size: var(--subtitle);
        line-height: 1.6rem;
        letter-spacing: 0.1em;
        margin-bottom: 1.2rem;
    }

.c-style-results .c-style-graph {
        margin: 4rem auto;

        width: 100%;
        height: 0;
        padding-top: 79%;
        position: relative;
    }

@media (min-width: 800px) {

.c-style-results .c-style-graph {
            margin: 6rem auto
    }
        }

@media (min-width: 800px) {

.c-style-results .c-style-graph {
            padding-top: 42%
    }
        }

.c-style-results .c-style-graph h3 {
            font-size: var(--h4);
            line-height: 2.7rem;
        }

@media (min-width: 800px) {

.c-style-results .c-style-graph h3 {
                font-size: var(--h3);
                line-height: 3.4rem
        }
            }

.c-style-results .c-style-graph-wrapper {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            cursor: pointer;
            @-webkit-keyframes pulse {
                100% {
                    -webkit-transform: scale(2);
                            transform: scale(2);
                    opacity: 0;
                }
            }
        }

.c-style-results .c-style-graph-wrapper::before {
                content: "";
                position: absolute;
                left: calc(50% - 0.1rem);
                top: 0;
                bottom: 0;
                width: 0.2rem;
                background: #e1e1e2;
            }

.c-style-results .c-style-graph-wrapper::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: calc(50% - 0.1rem);
                height: 0.2rem;
                background: #e1e1e2;
            }

.c-style-results .c-style-graph-wrapper .labels {
                position: absolute;
                background-color: var(--white);
                z-index: 1;
                color: var(--mid-gray);
                font-size: var(--p);
                line-height: 2.5rem;
            }

.c-style-results .c-style-graph-wrapper .labels__introvert,
                .c-style-results .c-style-graph-wrapper .labels__extrovert {
                    top: calc(50% - 1.4rem);
                }

.c-style-results .c-style-graph-wrapper .labels__introvert,
                .c-style-results .c-style-graph-wrapper .labels__facts {
                    padding-right: 0.5rem;
                }

.c-style-results .c-style-graph-wrapper .labels__extrovert,
                .c-style-results .c-style-graph-wrapper .labels__people {
                    padding-left: 0.5rem;
                }

.c-style-results .c-style-graph-wrapper .labels__introvert {
                    left: 0;
                }

.c-style-results .c-style-graph-wrapper .labels__extrovert {
                    left: calc(100% - 6.8rem);
                }

@media (min-width: 800px) {

.c-style-results .c-style-graph-wrapper .labels__extrovert {
                        left: calc(100% - 7.6rem)
                }
                    }

.c-style-results .c-style-graph-wrapper .labels__facts,
                .c-style-results .c-style-graph-wrapper .labels__people {
                    -webkit-transform-origin: left;
                            transform-origin: left;
                    left: calc(50% - 0.1rem);
                    -webkit-transform: rotate(-90deg);
                            transform: rotate(-90deg);
                }

.c-style-results .c-style-graph-wrapper .labels__facts {
                    bottom: -2rem;
                }

.c-style-results .c-style-graph-wrapper .labels__people {
                    top: 3.4rem;
                }

.c-style-results .c-style-graph-wrapper .connecting,
            .c-style-results .c-style-graph-wrapper .influencing,
            .c-style-results .c-style-graph-wrapper .examining,
            .c-style-results .c-style-graph-wrapper .delivering {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                color: var(--petrol);
            }

.c-style-results .c-style-graph-wrapper .influencing,
            .c-style-results .c-style-graph-wrapper .delivering {
                align-items: flex-end;
            }

.c-style-results .c-style-graph-wrapper .connecting,
            .c-style-results .c-style-graph-wrapper .influencing {
                margin-top: -1rem;
            }

.c-style-results .c-style-graph-wrapper .examining,
            .c-style-results .c-style-graph-wrapper .delivering {
                justify-content: flex-end;
                margin-bottom: -1rem;
            }

.c-style-results .c-style-graph-wrapper p.smaller {
                color: var(--orange);
            }

.c-style-results .c-style-graph-wrapper__dot-boundary {
                position: absolute;
                top: 7rem;
                right: 7rem;
                left: 7rem;
                bottom: 7rem;
            }

.c-style-results .c-style-graph-wrapper .dot {
                position: absolute;
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 50%;
                background: rgba(255, 110, 69, 1);
                -webkit-transform: translate(-.8rem, -.8rem);
                        transform: translate(-.8rem, -.8rem);
                z-index: 1;
            }

.c-style-results .c-style-graph-wrapper .dot::before, .c-style-results .c-style-graph-wrapper .dot::after {
                    content: "";
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    background: rgba(255, 110, 69, 1);
                    border-radius: 50%;
                    z-index: 0;
                    opacity: .7;
                }

.c-style-results .c-style-graph-wrapper .dot::before {
                    -webkit-animation: pulse 2s ease-out infinite;
                            animation: pulse 2s ease-out infinite;
                }

.c-style-results .c-style-graph-wrapper .dot::after {
                    -webkit-animation: pulse 2s 1s ease-in-out infinite;
                            animation: pulse 2s 1s ease-in-out infinite;
                }

@keyframes pulse {
                100% {
                    -webkit-transform: scale(2);
                            transform: scale(2);
                    opacity: 0;
                }
            }

.c-style-results .location-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

.c-style-results .retake-btn {
        width: 50%;
        text-align: right;
    }

.c-style-results .c-style-name-container {
        margin: 20px 0;
    }

@media screen and (max-width: 700px) {
        .c-style-results .result_quadrant__header {
            height: 10rem;
        }
        .c-style-results .result_quadrant__copy {
            padding: 1.5rem 2rem 0;
        }
    }

@media screen and (min-width: 700px) {
        .c-style-results .result_quadrant__header {
            height: 11rem !important;
        }
        .c-style-results .result_quadrant__copy {
            padding: 2rem;
        }

            .c-style-results .result_quadrant__copy p {
                text-transform: uppercase;
                margin-bottom: 5px;
            }
    }

@media screen and (min-width: 1070px) {
        .c-style-results .result_quadrant__copy {
            padding-top: 2rem !important;
        }
    }

.c-style-results .labels-video-player {
        display: none;
    }

.c-style-results .labels-video-playing {
        top: 0;
        left: 0;
        position: fixed;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
        background: rgba(255,255,255,.8);
        display: flex;
        justify-content: center;
        align-items: center;
    }

.c-style-results .video-container {
        position: relative;
    }

.c-style-results .video-container .video-close {
            position: absolute;
            right: 1rem;
            top: 1rem;
            cursor: pointer;
        }

.c-style-results .profile-c-style-span {
        color: black;
        font-weight: bold;
        font-size: var(--subtitle);
    }

.c-style-results .profile-c-style-span::before {
        content: '>';
        color: grey;
        padding: 0 5px;
    }

.c-style-results .c-style-banner-text {
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        padding: 6.5rem 2rem 0;
    }
.result_quadrant__header {
        height: 16rem;
        overflow: hidden;
        position: relative;
        top: 0; 
        left: 0;
        margin-bottom: 2.8rem;
    }
.result_quadrant__header svg {
            width: 130%;
        }
@media (min-width: 1024px) {
.result_quadrant__header svg {
                -webkit-transform: scale(1.8) translateY(+23%);
                        transform: scale(1.8) translateY(+23%)
        }
            }
.result_quadrant__copy {
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        padding: 6.5rem 2rem 0;
    }
.result_quadrant__copy h6.smaller {
            text-transform: uppercase;
            font-size: 1.5rem;
            padding-top: 1rem;
        }
.result_quadrant__copy h1 {
            text-transform: capitalize;
        }
.result_quadrant .profile-c-style-span {
        color: black;
        font-weight: bold;
        font-size: var(--subtitle);
    }
.result_quadrant .profile-c-style-span::before {
        content: '>';
        color: grey;
        padding: 0 5px;
    }
.result_quadrant .c-style-banner-text {
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        padding: 6.5rem 2rem 0;
    }

.main-zone-paragraph h1 {
        font-size: 2.2rem;
        font-weight: 600;
        margin: 2.5rem 0 .8rem;
        line-height: normal;
    }

.main-zone-paragraph .phone-view-video-container {
        display: none !important;
    }
.result_quadrant p {
        font-size: var(--p-larger);
        line-height: 2.7rem;
    }

        @media (min-width: 1024px) {.result_quadrant p {
            line-height: 3.2rem
    }
        }
    
    .result_quadrant p + .result_video {
        margin: 2.8rem 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .result_quadrant p + .result_video .video-wrapper {
            flex: 0 0 calc((100% - var(--gutter)) / 2);
            width: calc((100% - var(--gutter)) / 2);
            height: 0;
            padding-top: calc((100% - var(--gutter)) / 2 * 1138 / 640);
            position: relative;
            border-radius: 1.2rem;
            overflow: hidden;
        }
    
    .result_quadrant p + .result_video .video-wrapper iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
    
    .result_quadrant p + .result_video h5 {
            width: calc((100% - var(--gutter)) / 2);
            color: var(--orange);
        }
    
    @media screen and (max-width: 500px) {
    
    .result_quadrant p + .result_video {
            display: none
    }
        }
    
    .result_quadrant .phone-view-video-container h1 {
            font-size: 2.2rem;
            font-weight: 600;
            margin: 2.5rem 0 .8rem;
            line-height: normal;
        }
    
    .result_quadrant .phone-view-video-container p {
            margin: 0 0 30px 0;
        }
    
    .result_quadrant .result_video_phone_view {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    @media screen and (max-width: 500px) {
        .result_quadrant .phone-view-video-container {
            display: block;
        }
    }

.page-spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

  .page-spinner-container .spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .page-spinner-container .spinner-container .spinner {
      width: 100px;
      height: 100px;
      border-top: 5px solid var(--orange);
      border-right: 5px solid var(--orange);
      border-bottom: 5px solid var(--orange);
      border-left: 5px solid transparent;
      border-radius: 50%;
      -webkit-animation: spin 1s linear infinite;
              animation: spin 1s linear infinite;
    }

  .page-spinner-container .spinner-container h1 {
      font-size: 2rem;
      margin-top: 2rem;
    }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.contact-form-container {
  padding: 3rem 2rem 1rem 2rem;
  position: relative;
}

  .contact-form-container .closeModal {
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
  }

  .contact-form-container .info-text-container {
    padding: 1rem 2rem;
    margin-bottom: 1rem;
  }

  .contact-form-container .info-text-container p {
      font-size: 1.5rem;
    }

  .contact-form-container .form-wrapper {
    box-shadow: 0 0 5px rgba(0, 0, 0, .8);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
  }

  .contact-form-container .form-wrapper .email-top-section {
      padding: 1rem 2rem;
      background-color: var(--orange);
      color: white;
    }

  .contact-form-container .form-wrapper .email-bottom-section {
      height: 250px;
    }

  .contact-form-container .form-wrapper .email-bottom-section textarea {
        padding: 2rem 2rem 1rem 2rem;
        width: 100%;
        height: 100%;
        resize: none;
        border: none;
        outline: none;
        white-space: pre-wrap;
        font-size: 1.5rem;
        font-family: var(--font-family);
        background: rgb(235, 235, 235);
      }

  .contact-form-container .form-send-button {
    background-color: var(--petrol);
    color: white;
    padding: 1rem 2rem;
    border-radius: 50px;
    cursor: pointer;
    margin: 2rem auto 0 auto;
    width: 20%;
    text-align: center;
    cursor: pointer;
  }

  .contact-form-container .form-send-button:hover {
    opacity: .7;
  }

  .contact-form-container .server-response-status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
  }

  .contact-form-container .server-response-status svg {
      width: 8rem;
      height: 8rem;
      margin: 0rem auto;
    }

  .contact-form-container .server-response-status svg .cls-1 {
        fill: var(--orange);
      }

  .contact-form-container .server-response-status h1 {
      font-size: 2.2rem;
      margin: 2rem auto 3rem;
      line-height: normal;
      text-align: center;
    }

  .contact-form-container .server-response-status button {
      padding: 1rem 3rem;
      border-radius: 4rem;
      background-color: var(--petrol);
      color: white;
      margin: 0 auto;
    }

  .contact-form-container .server-response-status button:hover {
        opacity: .7;
      }
@media (min-width: 800px) {.courses-progress-chart {
        display: flex
}
    }
    .courses-progress-chart svg {
        width: 100%;
        max-width: 22rem;
        height: auto;
        margin: 0 auto;
        display: block;
    }
    .courses-progress-chart svg .chart-value {
            fill: white;
            font: 1.7px sans-serif;
        }
    .courses-progress-chart svg .chart-value.inverse {
                fill: var(--orange);
            }
    @media (min-width: 800px) {
    .courses-progress-chart svg {
            margin: 0;
            max-width: 36rem;
            padding-right: var(--gutter)
    }
        }
    .courses-progress-chart ul {
        margin: 2.8rem auto;
        font-size: var(--h5);
        line-height: 1.8;
        color: var(--petrol);
    }
    .courses-progress-chart ul em {
            font-style: normal;
            color: var(--mid-gray);
            display: none;
        }
    @media (min-width: 800px) {
    .courses-progress-chart ul {
            align-self: center;
            flex: 0 0 auto;
            margin-top: 4rem;
            margin-bottom: 4rem
    }
        }
    .courses-progress-chart .dot {
        display: inline-block;
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 3rem;
        margin-right: 2rem;
    }

/* padding-bottom: 10rem; */
    .topics-detail hr {
        margin-top: 2.8rem;
        margin-bottom: 2.8rem;
    }
    @media (min-width: 800px) {
    .topics-detail hr {
            max-width: calc(var(--wide-page-width) - 2 * var(--gutter));
            margin-left: auto;
            margin-right: auto
    }
        }
    .topics-detail__title {
        margin-top: 1.6rem;
        margin-bottom: 2rem;
    }
    @media (min-width: 800px) {
    .topics-detail__title {
            max-width: calc(var(--wide-page-width) - 2 * var(--gutter));
            margin-left: auto;
            margin-right: auto
    }
        }
    .topics-detail__title button {
            display: none;
        }
    @media (min-width: 800px) {
    .topics-detail__title {
            padding-bottom: 3.2rem;
            border-bottom: 4px solid var(--light-gray);
            margin-bottom: 4.8rem;
            display: flex;
            justify-content: space-between;
            align-items: center
    }

            .topics-detail__title button {
                display: inline-block;
                width: 17rem;
            }
        }
    .topics-detail__tile {
        cursor: pointer;
    }
    @media (min-width: 800px) {
    .topics-detail__tile {
            max-width: calc(var(--wide-page-width) - 2 * var(--gutter));
            margin-left: auto;
            margin-right: auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start
    }

            .topics-detail__tile hr {
                display: none;
            }
        }
    .topics-detail__tile .topics-tile__item {
                flex-direction: column-reverse;
            }
    .topics-detail__tile .topics-tile__item__title {
                    font-size: 2.5rem;
                    line-height: 3.4rem;
                }
    .topics-detail__tile .topics-tile__item__description {
                    display: none;
                }
    .topics-detail__tile .progress-bar {
            margin-top: -0.2rem;
        }
    @media (min-width: 800px) {
    .topics-detail__tile .progress-bar {
                margin-top: 1.6rem
        }
            }
    .topics-detail__back-link {
        text-transform: uppercase;
        font-size: var(--subtitle);
        line-height: 1.6rem;
        letter-spacing: 0.1em;
        color: var(--orange);
        font-weight: var(--font-weight-regular);
    }
    @media (min-width: 800px) {
    .topics-detail__back-link {
            display: block;
            max-width: calc(var(--wide-page-width) - 2 * var(--gutter));
            margin-left: auto;
            margin-right: auto
    }
        }
    @media (min-width: 800px) {
    .topics-detail__image {
            flex: 1 1 33%
    }
        }
    @media (min-width: 1440px) {
    .topics-detail__image {
            flex: 1 1 66%
    }
        }
    @media (min-width: 800px) {
    .topics-detail__summary {
            padding-left: 3.2rem;
            flex: 1 1 33%
    }
        }
    .topics-detail__summary p {
            font-style: normal;
            font-weight: var(--font-weight-regular);
            font-size: 2rem;
            line-height: 2.7rem;
            color: var(--dark-gray);
            margin-top: 1.2rem;
        }
    .topics-detail .section-with-badge:first-of-type {
            margin-top: 2.8rem;
            padding-bottom: 11rem;
        }
    .topics-detail .section-with-badge__title {
            font-size: 2.5rem;
            line-height: 3.4rem;
        }
    @media (min-width: 800px) {
    .topics-detail .section-with-badge__title {
                font-size: 3.5rem;
                line-height: 4.2rem;
                max-width: calc(var(--wide-page-width) - 2 * var(--gutter));
                margin-left: auto;
                margin-right: auto
        }
            }
    @media (min-width: 800px) {
    .topics-detail .page-wrapper {
            padding: calc(var(--gutter) * 2) var(--gutter) 0 0
    }
        }
    @media (min-width: 800px) {
    .topics-detail .action-panel {
            display: none
    }
        }

.loading-screen {
    background-color: var(--orange);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
}

    .loading-screen__logo {
        width: 15.4rem;
        height: 8.3rem;
    }

    .loading-screen__logo svg {
            height: 100%;
            width: 100%;
        }

    .loading-screen h5 {
        color: var(--white);
        margin-top: 1.9rem;
    }

@media (min-width: 800px) {.certifications {
        max-width: var(--narrow-page-width);
        margin: 0 auto
}
    }
    .certifications a {
        text-transform: uppercase;
        font-size: var(--subtitle);
        line-height: 1.6rem;
        letter-spacing: 0.1em;
        color: var(--orange);
        font-weight: var(--font-weight-regular);
    }
    .certifications p.larger {
            color: var(--dark-gray);
        }
    .certifications .section-with-badge {
        margin-top: 1.2rem;
        margin-bottom: 4.4rem;
    }
    .certifications .section-with-badge__badge {
        position: absolute;
        right: 0;
        top: 0.2em;
    }

.certificates-tile {
    border-bottom: 2px solid var(--light-gray);
    display: flex;
    cursor: pointer;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
}

    @media (min-width: 800px) {.certificates-tile {
        border-bottom: none
}
    }

    .certificates-tile__image {
        width: 4.3rem;
        height: 5.1rem;
        margin-right: 2rem;
    }

    .certificates-tile__image svg {
            width: 100%;
            height: 100%;
        }

    .certificates-tile__item {
        flex: 1 1;
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .certificates-tile__item__title {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

    .certificates-tile__item__title h4 {
                color: var(--petrol);
                margin-bottom: 1.2rem;
            }

    .certificates-tile__item__title h5 {
                color: var(--mid-gray);
            }

    .certificates-tile:last-of-type {
        border: none;
    }

.podcast-player {
    display: flex;
    flex-flow: row nowrap;
}

    .podcast-player__image {
        width: 6rem;
        height: 6rem;
        display: block;
        background-color: var(--light-gray);
        border-radius: 3rem;
        overflow: hidden;
        object-fit: contain;
    }

    .podcast-player__image-wrapper {
    }

    .podcast-player__title-wrapper {
        flex: 1 1 50%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
    }

    .podcast-player__playback-type {
        color: var(--orange);
        text-transform: uppercase;
        font-size: 1.3rem;
        line-height: 1.6rem;
        margin-bottom: 0.3rem;
    }

    .podcast-player__marquee {
        height: 2.1rem; /* equal to text line height */
        width: 90%;

        overflow: hidden;
        position: relative;

        @-webkit-keyframes marquee {
            0% {
                left: 0;
            }
            100% {
                left: -100%;
            }
        }
    }

    .podcast-player__marquee div {
            display: block;
            width: 200%;

            position: absolute;
            overflow: hidden;

            -webkit-animation: marquee 10s linear infinite;

                    animation: marquee 10s linear infinite;
        }

    .podcast-player__marquee div:hover {
                -webkit-animation-play-state: paused;
                        animation-play-state: paused;
            }

    .podcast-player__marquee span {
            float: left;
            width: 50%;
            color: var(--petrol);
            font-size: 1.6rem;
            line-height: 2.1rem;
        }

    @keyframes marquee {
            0% {
                left: 0;
            }
            100% {
                left: -100%;
            }
        }

    .podcast-player__marquee:before,
        .podcast-player__marquee:after {
            content: "";
            width: 5rem;
            height: 100%;
            position: absolute;
            top: 0;
            background: var(--gradient-white-ltr);
            z-index: 1;
        }

    @media (min-width: 800px) {

    .podcast-player__marquee:before,
        .podcast-player__marquee:after {
                width: 10rem
        }
            }

    .podcast-player__marquee:before {
            left: 0;
            -webkit-transform: rotate(-180deg);
                    transform: rotate(-180deg);
        }

    .podcast-player__marquee:after {
            right: 0;
        }

    .podcast-player__button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .podcast-player__mini-play-button,
    .podcast-player__expand-button {
        margin-right: 2rem;
        cursor: pointer;
    }

    .podcast-player__mini-close-button {
        /* top: 3.4rem;
        right: 3.4rem; */
        padding-left: 2rem;
        cursor: pointer;
        height: 5.6rem;
        border-left: 1px solid var(--mid-gray);
        display: flex;
        align-items: center;
        color: var(--dark-gray);
    }

    .podcast-player__mini-close-button:hover {
            color: var(--red);
        }

    .podcast-player__mini-close-button svg {
            width: 1.8rem;
        }

    .podcast-player__mini-close-button svg path {
                fill: currentColor;
            }

.expandable-div-container .expandable-div-header {
        padding-bottom: 1rem;
    }

        .expandable-div-container .expandable-div-header .view-more-span {
            font-size: 1.5rem;
            display: inline-flex;
            align-items: center;
            margin-left: .2rem;
            cursor: pointer;
            color: rgb(41, 142, 242);
        }

        .expandable-div-container .expandable-div-header .view-more-span .label-button {
                width: 8rem;
                text-align: right;
            }

        .expandable-div-container .expandable-div-header .view-more-span svg {
                margin-left: 5px;
            }
    .expandable-div-container .expandable-div-content {
        display: none;
    }

.rotate-up {
    transition: .5s;
}
.rotate-down {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    transition: .5s;
}

@media screen and (min-width: 800px) {.cStyle-results-container {
    max-width: var(--wide-page-width);
    margin: 0 auto
}
  }
  .cStyle-results-container .location-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .cStyle-results-container .location-header a {
      text-transform: uppercase;
      font-size: var(--subtitle);
      line-height: 1.6rem;
      letter-spacing: .1em;
      margin-bottom: 1.2rem;
    }
  .cStyle-results-container .location-header span {
      color: #000;
      font-weight: 700;
      font-size: var(--subtitle);
    }
  .cStyle-results-container .location-header span:before {
        content: ">";
        color: grey;
        padding: 0 5px;
      }
  .cStyle-results-container .location-header .retake-btn {
      width: 50%;
      text-align: right;
    }
  .cStyle-results-container .location-header-buttons {
    display: flex;
    justify-content: space-between;
    grid-gap: 5rem;
    grid-gap: 5rem;
    gap: 5rem;
  }
  .cStyle-results-container .location-header-buttons button {
      width: 100%;
    }
  @media screen and (max-width: 700px) {
  .cStyle-results-container .location-header-buttons {
      flex-direction: column;
      grid-gap: 1rem;
      grid-gap: 1rem;
      gap: 1rem
  }
    }
  .cStyle-results-container .c-style-name-container {
    z-index: 2;
    margin: 2rem 0;
    height: 10rem;
    width: calc(100% + 2 * var(--gutter));
    -webkit-transform: translateX(calc(-1 * var(--gutter)));
            transform: translateX(calc(-1 * var(--gutter)));
    overflow: hidden;
    position: relative;
  }
  @media (min-width: 1024px) {
  .cStyle-results-container .c-style-name-container {
      height: 11rem
  }
    }
  .cStyle-results-container .c-style-name-container svg {
      width: 130%;
    }
  @media (min-width: 1024px) {
  .cStyle-results-container .c-style-name-container svg {
        -webkit-transform: scale(1.8) translateY(+23%);
                transform: scale(1.8) translateY(+23%)
    }
      }
  .cStyle-results-container .c-style-name-container .style-text-container {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      color: white;
      padding: 1.5rem 2rem;
    }
  @media (min-width: 800px) {
  .cStyle-results-container .c-style-name-container .style-text-container {
        padding: 2rem 3rem
    }
      }
  .cStyle-results-container .c-style-name-container .style-text-container h6 {
        text-transform: uppercase;
        font-size: 1.5rem;
        padding-top: 1rem;
      }
  .cStyle-results-container .static-text {
    font-size: var(--p-larger);
    margin-top: 2rem;
  }
  .cStyle-results-container .main-zone-paragraph h1 {
      font-size: 2.2rem;
      font-weight: 600;
      margin: 2.5rem 0 1.5rem;
      line-height: normal;
      text-transform: uppercase;
    }
  .cStyle-results-container .main-zone-paragraph p {
      font-size: var(--p-larger);
    }
  .cStyle-results-container .main-zone-paragraph p span {
        font-weight: 400;
      }
  .cStyle-results-container .main-zone-paragraph ul {
      list-style-type: disc;
      font-weight: 100;
      margin: 2rem;
    }
  .cStyle-results-container .main-zone-paragraph ul li {
        margin-bottom: .5rem;
        font-size: 2rem;
      }
  .cStyle-results-container .main-zone-paragraph ul li span {
          font-weight: 400;
        }
  .cStyle-results-container .personalised-video-container {
    padding-top: 0;
    margin: 3rem auto;
  }
  .cStyle-results-container .personalised-video-container h1 {
      font-size: 2.2rem;
      font-weight: 600;
      margin-bottom: .8rem;
      line-height: normal;
    }
  .cStyle-results-container .personalised-video-container p {
      font-size: var(--p-larger);
      margin-bottom: 1.5rem;
    }
  @media screen and (min-width: 800px) {
  .cStyle-results-container .personalised-video-container p {
        margin-bottom: 2.5rem
    }
      }
  .cStyle-results-container .recommended-video-container {
    padding: 2rem 0;
    margin-bottom: 1rem;
  }
  .cStyle-results-container .recommended-video-container h1 {
      font-size: 2.2rem;
      font-weight: 600;
    }
  .cStyle-results-container .recommended-video-container .videos-container {
      display: flex;
      justify-content: space-between;
      padding-top: 2.5rem;
    }
  .cStyle-results-container .recommended-video-container .videos-container .round-gradient {
        transition: .5s;
        cursor: pointer;
        border-radius: 50%;
        background-size: cover;
        height: 100px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }
  .cStyle-results-container .recommended-video-container .videos-container .round-gradient .cstyle-picture {
          background-size: 100px;
          background-position-x: 50%;
          background-position-y: 50%;
          height: 90%;
          width: 90%;
          border-radius: 50%;
        }
  .cStyle-results-container .recommended-video-container .videos-container .round-gradient svg {
          position: absolute;
          top: calc(50% - 15px);
          left: calc(50% - 15px);
          color: var(--orange);
          width: 30px;
          height: 30px;
        }
  .cStyle-results-container .recommended-video-container .videos-container .round-gradient:hover {
        opacity: .7;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        transition: .5s;
      }
  .cStyle-results-container .recommended-video-container .videos-container .video-labels-container {
        padding-top: 1.5rem;
      }
  .cStyle-results-container .recommended-video-container .videos-container .video-labels-container h1,
        .cStyle-results-container .recommended-video-container .videos-container .video-labels-container p {
          text-align: center;
          line-height: 2rem;
          font-size: 1.5rem;
        }
  @media screen and (min-width: 700px) {
  .cStyle-results-container .recommended-video-container .videos-container {
        justify-content: space-evenly
    }

        .cStyle-results-container .recommended-video-container .videos-container .round-gradient {
          height: 150px;
          width: 150px;
        }

          .cStyle-results-container .recommended-video-container .videos-container .round-gradient .cstyle-picture {
            background-size: 150px;
          }
      }
  .cStyle-results-container .email-container {
    width: calc(100% - 2 * var(--gutter));
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 2rem;
    box-shadow: 0 0 10px 3px lightgrey;
  }
  @media screen and (min-width: 800px) {
  .cStyle-results-container .email-container {
      max-width: var(--wide-page-width-minus-gutter);
      margin: auto auto
  }
    }
  .cStyle-results-container .email-container button {
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40%;
      padding: 0.3rem;
    }
  .cStyle-results-container .email-container button svg {
        width: 2.5rem;
        height: 2.5rem;
      }
  .cStyle-results-container .email-container button h1 {
        display: none;
      }
  @media screen and (min-width: 800px) {
  .cStyle-results-container .email-container button {
        padding: 1rem
    }

        .cStyle-results-container .email-container button h1 {
          display: block;
          font-size: 2.2rem;
          padding-left: 1rem;
          line-height: normal;
        }
      }
  .cStyle-results-container .email-container .sendEmailBtn {
      margin-right: 1rem;
    }
  .cStyle-results-container .email-container .sendInviteBtn {
      margin-left: 1rem;
    }
  .cStyle-results-container .customHR {
    width: calc(90% - 2 * var(--gutter));
    border-top: 3px solid lightgray;
    border-radius: 1px;
    margin: 0 auto 3rem auto;
  }
  @media screen and (min-width: 800px) {
  .cStyle-results-container .customHR {
      max-width: var(--wide-page-width-minus-gutter);
      margin: 2rem auto 5rem auto
  }
    }
  .cStyle-results-container .h30px {
    position: relative;
    height: 30px;
  }
  .cStyle-results-container .show-scrollable-container {
    position: absolute;
    height: 50px;
    right: 50px;
    bottom: 50px;
    font-size: 30px;
    color: #2369DC;
    cursor: pointer;
  }
  .cStyle-results-container .show-scrollable-container .show-scrollable {
      -webkit-animation-name: showScrollableAnimation;
              animation-name: showScrollableAnimation;
      -webkit-animation-duration: 2s;
              animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease-in-out;
              animation-timing-function: ease-in-out;
    }

.bottom-btns-container {
  display: block;
  position: relative;
  padding-bottom: 20px;
  margin-top: 3rem;
}

.bottom-btns-container button {
    display: block;
    width: 100%;
    margin: .8rem auto;
  }

@media (min-width: 800px) {

.bottom-btns-container {
    display: flex;
    justify-content: space-between
}

    .bottom-btns-container button {
      width: 47%;
    }
  }

.section-header {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: .8rem;
  line-height: normal;
  text-transform: uppercase;
}

@-webkit-keyframes showScrollableAnimation {

  /* 5%{ 
    transform: translateY(-5px);
  } */
  30% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }

  40% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }

  60% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes showScrollableAnimation {

  /* 5%{ 
    transform: translateY(-5px);
  } */
  30% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }

  40% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }

  60% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
.progress-bar-container {
  margin-bottom: 3rem;
}
  .progress-bar-container h2 {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: normal;
  }
  .progress-bar-container p {
    width: 80%;
  }
  @media screen and (min-width: 500px) {
  .progress-bar-container p {
      width: 90%
  }
    }
  .progress-bar-container .bar-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .progress-bar-container .bar-container .grey-bar {
      position: relative;
      border-radius: 40px;
      height: 1.8rem;
      width: 80%;
      background: var(--light-gray);
    }
  @media screen and (min-width: 500px) {
  .progress-bar-container .bar-container .grey-bar {
        width: 90%
    }
      }
  .progress-bar-container .bar-container .grey-bar .orange-bar {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 40px;
        background: var(--orange);
        transition: 3s;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  .progress-bar-container .bar-container .grey-bar .orange-bar p {    
          font-size: 1.6rem;
          position: absolute;
          right: -4rem;
          font-weight: 400;
        }
  .progress-bar-container .bar-container p {
      color: var(--orange);
      width: auto;
      cursor: pointer;
    }
.center {
  display: flex;
  align-items: center;
  margin-top: 0;
}
.hide {
  display: none;
}



.assertiveness-results .page-wrapper {
    padding-bottom: 0;
  }
  @media (min-width: 800px) {.assertiveness-results {
      max-width: var(--wide-page-width);
      margin: 0 auto
}
          .assertiveness-results .action-panel button {
              margin: 0 auto;
          }
  }
  .assertiveness-results a {
    text-transform: uppercase;
    font-size: var(--subtitle);
    line-height: 1.6rem;
    letter-spacing: 0.1em;
    margin-bottom: 1.2rem;
  }
  .assertiveness-results .location-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .assertiveness-results .retake-btn {
      width: 50%;
      text-align: right;
  }
  .assertiveness-results .retake-btn button {
        width: auto;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
      }
  @media screen and (min-width: 500px) {
  .assertiveness-results .retake-btn button {
          width: 90%
      }
        }
  .assertiveness-results .profile-span {
    color: black;
    font-weight: bold;
    font-size: var(--subtitle);
  }
  .assertiveness-results .profile-span::before {
      content: '>';
      color: grey;
      padding: 0 5px;
    }
  .assertiveness-results .text-section {
    margin: 4rem 0 2.5rem;
  }
  @media screen and (min-width: 800px) {
  .assertiveness-results .text-section {
      margin: 7rem 0 2.5rem
  }
    }
  .assertiveness-results .text-section h3 {
      font-size: 1.7rem;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 2rem;
    }
  @media screen and (min-width: 800px) {
  .assertiveness-results .text-section h3 {
        margin: 4rem 0;
        font-size: var(--p-larger)
    }
      }
  .assertiveness-results .text-section p {
      margin-bottom: 2rem;
      font-size: 1.6rem;
    }
  @media screen and (min-width: 800px) {
  .assertiveness-results .text-section p {
        font-size: var(--p-larger)
    }
      }
  .assertiveness-results .text-section p strong {
        font-weight: normal;
        color: var(--orange);
      }
  .assertiveness-results .chart-section {
    margin-top: 4rem;
  }
  .assertiveness-results .chart-section h1 {
      font-size: 2rem;
      font-weight: 600;
    }
  .assertiveness-results .bottom-text-section {
    margin-top: 4rem;
  }
  @media screen and (min-width: 800px) {
  .assertiveness-results .bottom-text-section {
      margin-top: 7rem
  }
    }
  .assertiveness-results .bottom-text-section h3 {
      font-size: 1.7rem;
      font-weight: 600;
      line-height: normal;
    }
  @media screen and (min-width: 800px) {
  .assertiveness-results .bottom-text-section h3 {
        font-size: var(--p-larger)
    }
      }
  .assertiveness-results .bottom-text-section p {
      margin-top: 2rem;
      font-size: 1.6rem;
    }
  @media screen and (min-width: 800px) {
  .assertiveness-results .bottom-text-section p {
        font-size: var(--p-larger)
    }
      }
  .assertiveness-results .bottom-text-section p strong {
        font-weight: normal;
        color: var(--orange);
      }
  .assertiveness-results .personalised-video-container {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
  }
  .assertiveness-results .recommended-videos-container {
    margin: 4rem 0;
    display: block;
  }
  .assertiveness-results .recommended-videos-container h1 {
      font-size: 2rem;
      font-weight: 600;

    }
  .assertiveness-results .recommended-videos-container .video-container {
      display: flex;
      align-items: center;
      margin: 2rem 0;
      padding-left: 4%;
    }
  @media screen and (min-width: 700px) {
  .assertiveness-results .recommended-videos-container .video-container {
        display: block;
        padding-left: 0
    }
      }
  .assertiveness-results .recommended-videos-container .video-container .round-gradient {
        cursor: pointer;
        transition: 1s;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }
  .assertiveness-results .recommended-videos-container .video-container .round-gradient:hover {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1);
          transition: .5s;
          opacity: .5;
        }
  @media screen and (min-width: 700px) {
  .assertiveness-results .recommended-videos-container .video-container .round-gradient {
          margin: 1rem auto;
          width: 150px;
          height: 150px
      }
        }
  .assertiveness-results .recommended-videos-container .video-container .round-gradient .cstyle-picture {
          width: 90%;
          height: 90%;
          background-size: cover;
          border-radius: 50%;
        }
  .assertiveness-results .recommended-videos-container .video-container .round-gradient svg {
          position: absolute;
          top: calc(50% - 15px);
          left: calc(50% - 15px);
          color: var(--orange);
          width: 30px; 
          height: 30px;
        }
  .assertiveness-results .recommended-videos-container .video-container .video-labels-container {
        margin-left: 3rem;
      }
  @media screen and (min-width: 700px) {
  .assertiveness-results .recommended-videos-container .video-container .video-labels-container {
          margin-left: 0;
          padding-top: 1.5rem;
          text-align: center
      }
        }
  .assertiveness-results .recommended-videos-container .video-container .video-labels-container h1 {
          font-size: 1.5rem;
          line-height: normal;
        }
  @media screen and (min-width: 700px) {
      .assertiveness-results .recommended-videos-container .videos-container {
        display: flex;
        justify-content: space-evenly;
      }
    }
  .assertiveness-results .orange-banner-container {
    width: 100vw;
    -webkit-transform: translateX(calc(-1 * var(--gutter)));
            transform: translateX(calc(-1 * var(--gutter)));
  }
  @media screen and (min-width: 700px) {
  .assertiveness-results .orange-banner-container {
      width: 100%;
      -webkit-transform: translateX(0);
              transform: translateX(0);
      border-radius: 7px
  }
    }
  .assertiveness-results .orange-banner-container h3 {
      color: white;
      margin: 2rem 0;
    }
  .assertiveness-results .bottom-btns-container {
    width: 100vw;
    -webkit-transform: translateX(calc(-1 * var(--gutter)));
            transform: translateX(calc(-1 * var(--gutter)));
  }
  @media screen and (min-width: 800px) {
  .assertiveness-results .bottom-btns-container {
      width: 100%;
      -webkit-transform: translateX(0);
              transform: translateX(0)
  }
    }
  .assertiveness-results .labels-video-player {
    display: none;
  }
  .assertiveness-results .labels-video-playing {
      top: 0;
      left: 0;
      position: fixed;
      z-index: 1000;
      width: 100vw;
      height: 100vh;
      background: rgba(255,255,255,.8);
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .assertiveness-results .video-container {
      position: relative;
  }
  .assertiveness-results .video-container .video-close {
          position: absolute;
          right: 1rem;
          top: 1rem;
          cursor: pointer;
      }
.pwc-results {
  background: white;
}
  @media (min-width: 800px) {.pwc-results {
    max-width: var(--wide-page-width);
    margin: 0 auto
}
        .pwc-results .action-panel button {
            margin: 0 auto;
        }
  }
  .pwc-results a {
    text-transform: uppercase;
    font-size: var(--subtitle);
    line-height: 1.6rem;
    letter-spacing: 0.1em;
    margin-bottom: 1.2rem;
  }
  .pwc-results .location-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .pwc-results .retake-btn {
      width: 50%;
      text-align: right;
  }
  .pwc-results .retake-btn button {
        width: auto;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
      }
  @media screen and (min-width: 500px) {
  .pwc-results .retake-btn button {
          width: 90%
      }
        }
  .pwc-results .profile-span {
    color: black;
    font-weight: bold;
    font-size: var(--subtitle);
  }
  .pwc-results .profile-span::before {
      content: '>';
      color: grey;
      padding: 0 5px;
    }

.long {
  display: none;
}
@media screen and (min-width: 1220px) {
  .short {
    display: none;
  }
  .long {
    display: inline;
  }
}
.no-data-label {
  margin: 40% 0;
  display: flex;
  justify-content: center;
}

  .no-data-label label {
    color: lightslategrey;
  }
.job-legend {
  margin: 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

  .job-legend__color {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 5px;
    border-radius: 50%;
    border-color: transparent;
    background-color: blue;
  }

  .job-legend__title {
    font-weight: 400;
    font-size: 1.5rem;
  }

  .job-legend:hover {
    opacity: .9;
  }
.chart-with-legend {
  width: 50%;
  text-align: center;
}

  .chart-with-legend label {
    font-size: 2rem;
  }

  .chart-with-legend .chart-group .chart-container {
      width: 50%;
      margin: 2rem auto;
    }

  .chart-with-legend .chart-group .legends-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
.pie-charts-container {
  display: flex;
}
.pannel-control {
  display: flex;
}

  .pannel-control .slide-toggle {
    min-width: 45px;
    height: 22px;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 20px;
    background: rgb(219, 219, 219);
    border: 1px solid rgb(191, 189, 189);
    transition: all .4s ease-in;
    position: relative;
  }

  .pannel-control .slide-toggle .toggle-button {
      width: 20px;
      height: 20px;
      background: rgb(219, 219, 219);
      border-radius: 50%;
      border: 1px solid rgb(191, 189, 189);
      transition: all .3s ease-in;
    }

  .pannel-control .slide-toggle .toggle-button-on {
      -webkit-transform: translateX(120%);
              transform: translateX(120%);
    }

  .pannel-control .slide-toggle-on {
    background-color: var(--orange);
  }

  .pannel-control p {
    font-weight: 400;
    font-size: 1.7rem;
  }
.pannel-container {
  width: 100%;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 0 0 10px -4px;
  align-items: center;
  justify-content: center;
  color: rgb(110, 108, 108);
}

  .pannel-container__jobs {
    display: flex;
    margin-bottom: 2rem;
  }

  .pannel-container .pannel-controls-container {
    display: flex;
    margin-left: 20px;
  }

  .pannel-container .pannel-controls-container>* {
      margin: 0 10px;
    }
@media screen and (min-width: 800px) {.global-map-container {
    max-width: var(--wide-page-width);
    margin: 0 auto
}
  }
  .global-map-container .gmap-section {
    margin: 5rem 0;
  }
  .global-map-container .gmap-section h1 {
      font-size: 3rem;
      font-weight: 400;
      border-bottom: 2px solid rgba(0, 0, 0, .1);
      margin-bottom: 2rem;
    }

.pulse-btn {
  display: block;
  margin: 0 auto;
  border-radius: 50px;
  padding: 15px 50px;
  background-color: #f5ab0e;
  color: white;
  border: none;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 600;
  font-family: 'GT Walsheim';
}
.loading-screen-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1001;
}
.custom-modal {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: rgba(255, 255, 255, .7);
  display: flex;
  justify-content: center;
  align-items: center;
}

  .custom-modal .modal-text {
    background: var(--petrol);
    color: white;
    width: 80%;
    padding: 10rem 4rem;
    border-radius: 40px;
    position: relative;
  }

  @media screen and (min-width: 600px) {

  .custom-modal .modal-text {
      width: 60%;
      padding: 12rem 6rem;
      font-size: 2rem
  }
    }

  @media screen and (min-width: 1500px) {

  .custom-modal .modal-text {
      width: 20%;
      padding: 12rem 6rem;
      font-size: 2rem
  }
    }

  .custom-modal .modal-text button {
      position: absolute;
      top: 2rem;
      right: 3rem;
      font-size: 3rem;
      padding: 0;
      background: transparent;
      color: white;
      border: none;
    }

  .custom-modal .modal-text h1 {
      font-size: 1.8rem;
      line-height: normal;
    }
.radio-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: .5rem 0;
    font-size: 2rem;
}

    .radio-button__box {
        width: 3rem;
        height: 3rem;
        border: .2rem solid var(--orange);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: .5rem;
    }

    .radio-button__box--selected {
            width: 70%;
            height: 70%;
            background-color: tomato;
            border-radius: 50%;
        }
.jobs-container {
    margin: 2rem 0;
}

    @media screen and (min-width: 800px) {.jobs-container {
        display: flex;
        justify-content: space-between
}
    }
.inter-screen-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--gutter);
}

  .inter-screen-container .inter-screen {
    width: 100%;
  }

  .inter-screen-container .inter-screen__header {
      font-size: 3rem;
      font-weight: 900;
      line-height: 1.3;
      margin: 4rem 0;
    }

  .inter-screen-container .inter-screen__timer h1 {
        font-size: 2.2rem;
        line-height: 150%;
      }

  .inter-screen-container .inter-screen__timer__user-number {
        font-size: 3rem;
      }

  .inter-screen-container .inter-screen__job-survey {
      margin-top: 2rem;
    }

  .inter-screen-container .inter-screen__job-survey__title {
        font-size: 2.2rem;
        line-height: 150%;
      }

  @media screen and (min-width: 800px) {

  .inter-screen-container .inter-screen {
      max-width: 66rem;
      margin: 0 auto;
      -webkit-transform: translate(0);
              transform: translate(0)
  }

      .inter-screen-container .inter-screen__header {
        text-align: center;
      }

      .inter-screen-container .inter-screen__timer {
        text-align: center;
      }

      .inter-screen-container .inter-screen__job-survey {
        margin: 10rem 0;
      }
    }

.envelope-btn {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.envelope-btn svg {
    width: 3rem;
    margin-right: .5rem;
  }
.search-select-container {
  width: 100%;
  height: 100%;
  position: relative;
}

  .search-select-container .select-fields {
    position: absolute;
    width: 100%;
    margin: 0 auto;
    top: 50px;
    left: 0;
    box-shadow: 0 2px 5px -2px black;
    border-radius: 4px;
    z-index: 5;
    background-color: white;
  }

  .search-select-container .select-fields h1 {
      font-size: 2rem;
      padding: 1rem;
      line-height: normal;
    }

.h1-hover {
  background-color: lightblue;
  cursor: pointer;
}

.d-none {
  display: none;
}
.sr-survey-container {
  width: 100%;
  height: 100%;
}

  .sr-survey-container .submit-btn {
    display: block;
    margin: 0 auto;
  }

  @media (min-width: 800px) {.sr-survey-container {
    width: 50rem;
    height: auto
}
  }


* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    height: 100%;
    overflow-x: hidden;
    overscroll-behavior-x: none;
}

#root {
    height: 100%;
}

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
}

ul,
ol {
    margin: 0;
    padding: 0;

    list-style: none;
}

button {
    cursor: pointer;
}

figure {
    display: block;
    margin: 0;
}

img {
    max-width: 100%;
}
body {
    color: var(--black);
    font-family: var(--font-family);
    font-weight: var(--font-weight-regular);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: var(--font-weight-regular);
}

h1 {
    font-size: var(--h1);
    line-height: 4.7rem;
}

h1.big {
        font-size: var(--h1-big);
        line-height: 6.2rem;
    }

@media (min-width: 1024px) {

h1.big {
            line-height: 7.6rem
    }
        }

@media (min-width: 1024px) {

h1 {
        line-height: 6rem
}
    }

h2 {
    font-size: var(--h2);
    line-height: 3.7rem;
}

@media (min-width: 1024px) {

h2 {
        line-height: 4.8rem
}
    }

h3 {
    font-size: var(--h3);
    line-height: 3.4rem;
}

@media (min-width: 1024px) {

h3 {
        line-height: 4.2rem
}
    }

h4 {
    font-size: var(--h4);
    line-height: 2.7rem;
}

@media (min-width: 1024px) {

h4 {
        line-height: 3.4rem
}
    }

h5 {
    font-size: var(--h5);
    line-height: 2.1rem;
}

@media (min-width: 1024px) {

h5 {
        line-height: 2.8rem
}
    }

p {
    margin: 0;
    font-size: var(--p);
    line-height: 2.4rem;
    font-weight: var(--font-weight-light);
}

p.smaller {
        font-size: var(--p-smaller);
        line-height: 1.8rem;
        font-weight: var(--font-weight-regular);
    }

@media (min-width: 1024px) {

p.smaller {
            line-height: 2rem
    }
        }

p.larger {
        font-size: var(--p-larger);
        line-height: 2.7rem;
    }

@media (min-width: 1024px) {

p.larger {
            line-height: 3.2rem
    }
        }

p.subtitle {
        font-size: var(--subtitle);
        line-height: 1.6rem;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        color: var(--orange);
        font-weight: var(--font-weight-regular);
    }

@media (min-width: 800px) {

p.subtitle {
            font-size: var(--p);
            line-height: 2.2rem
    }
        }

p.subtitle + h1 {
        margin-top: 1.2rem;
    }

p + p {
    margin-top: 1rem;
}

a {
    color: var(--orange);
    text-decoration: none;
}

:root {
    /* grid */
    --gutter: 20px;

    /* colors */

    /* primary */
    --orange: #f86459;
    --yellow: #ffb151;

    /* secondary */
    --petrol: #07586b;
    --red: #f53b3b;

    /* grayscale */
    --black: #22292b;
    --dark-gray: #676b6d;
    --mid-gray: #c2c4c4;
    --gray: #e1e1e2;
    --light-gray: #f0f0f0;
    --white: #ffffff;

    /* delivering */
    --dark-blue: #0c3c84;
    --light-blue: #a3ced2;

    /* influencing */
    --dark-red: #9b1e1e;
    --light-red: #ffa5a6;

    /* examining */
    --green: #1c9381;
    --light-green: #ffc378;

    /* connecting */
    --blue: #245390;
    --pink: #ffa5a6;

    --gradient-white: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
    );

    /* left to right */
    --gradient-white-ltr: linear-gradient(
        270deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 89.22%
    );

    /* typography */

    --font-family: "GT Walsheim";
    --font-weight-light: 300;
    --font-weight-regular: 400;

    --base-font-size: 1.6rem;
    --scale: 1.25;

    --p: var(--base-font-size);
    --p-smaller: calc(var(--base-font-size) / (var(--scale)));
    --p-larger: calc(var(--base-font-size) * var(--scale));

    --subtitle: calc(var(--base-font-size) / (var(--scale)));

    --h5: var(--base-font-size);
    --h4: calc(var(--h5) * var(--scale));
    --h3: calc(var(--h4) * var(--scale));
    --h2: calc(var(--h3) * var(--scale));
    --h1: calc(var(--h2) * var(--scale));
    --h1-big: calc(var(--h1) * var(--scale));

    /* header */

    --header-height: 6.6rem;
    --header-margin: 0.8rem;
    --survey-header-height: 11.5rem;

    /* main container max-width */
    --page-width: calc(76.8rem - var(--gutter) * 2);
    --gradient-width: 10rem;
    --narrow-page-padding: 22rem;

    --narrow-page-width: calc(
        var(--page-width) - (var(--narrow-page-padding) * 2)
    );
    --wide-page-width: var(--page-width);
    --wide-page-width-minus-gutter: calc(
        var(--wide-page-width) - var(--gutter) * 2
    );

    --survey-min-height: calc(
        var(--vh, 1vh) * 100 - var(--survey-header-height) - 2.8rem - 10rem
    );
}

    /* breakpoints as in ./breakpoints.css file */

    @media (min-width: 800px) {:root {
        --base-font-size: 1.8rem;
        --gutter: 30px;
        --narrow-page-width: 64rem
}
    }

    @media (min-width: 1024px) {:root {
        --page-width: calc(100vw - var(--narrow-page-padding) * 2)
}
    }

    @media (min-width: 1440px) {:root {
        --page-width: 110rem;
        --wide-page-width: 104rem
}
    }

/* inputs */
input {
    display: block;
    margin-bottom: 3.6rem;
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--mid-gray);
    color: var(--dark-gray);
    padding: 0.8rem 0;
    font-weight: var(--font-weight-light);
    font-family: var(--font-family);
}

input::-webkit-input-placeholder {
    font-size: 2rem;
    line-height: 2.7rem;
    color: var(--dark-gray);
}

input:-ms-input-placeholder {
    font-size: 2rem;
    line-height: 2.7rem;
    color: var(--dark-gray);
}

input,
input::placeholder {
    font-size: 2rem;
    line-height: 2.7rem;
    color: var(--dark-gray);
}

input:focus {
    border-bottom: 1px solid var(--petrol);
    outline: none;
}

button {
    outline: none;
}

.no-scroll {
    overflow: hidden;
}

/* buttons */
.button-primary,
.button-secondary {
    border-radius: 3.2rem;
    padding: 1.5rem 0;
    font-size: 2rem;
    width: 100%;
    font-family: var(--font-family);
    font-weight: var(--font-weight-regular);
}
@media (min-width: 800px) {
.button-primary,
.button-secondary {
        width: 27rem
}
    }
.button-primary:hover, .button-secondary:hover {
        opacity: 0.7;
    }

.button-primary {
    background-color: var(--petrol);
    border: none;
    color: var(--white);
}

.button-primary--disabled {
        background-color: var(--light-gray);
        color: var(--mid-gray);
    }

.button-primary--disabled:hover {
            opacity: 1;
            cursor: not-allowed;
            ;
        }

.button-secondary {
    background-color: var(--white);
    border: 2px solid var(--petrol);
    color: var(--petrol);
}

.button-secondary--disabled {
        background-color: var(--light-gray);
        color: var(--mid-gray);
        border: none;
    }

.button-secondary--disabled:hover {
            opacity: 1;
            cursor: not-allowed;
        }

.collapse-css-transition {
    transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
}

.collapse-css-transition::after {
        content: "";
        position: absolute;
        bottom: 0;
        /* height: 3rem; */
        width: 100%;
        background: var(--gradient-white);
        transition: opacity 0.3s;
    }

.collapse-css-transition.--c-expanded::after,
    .collapse-css-transition.--c-expanding::after {
        opacity: 0;
    }
@font-face {
    font-family: "GT Walsheim";
    src: url(/static/media/GT-Walsheim-Light.175e7ae4.woff2) format("woff2"),
        url(/static/media/GT-Walsheim-Light.acdd3f7b.woff) format("woff");
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: "GT Walsheim";
    src: url(/static/media/GT-Walsheim-Regular.50a0b407.woff2) format("woff2"),
        url(/static/media/GT-Walsheim-Regular.d34db686.woff) format("woff");
    font-style: normal;
    font-weight: 400;
}

