nav {
    width: 100%;
    height: calc(100% - var(--header-height));
    position: absolute;
    top: var(--header-height);
    left: 0;
    background-color: transparent;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    @media (--tablet) {
        /* padding: 0 var(--narrow-page-padding); */
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    ul + ul {
        margin-top: 2.8rem;
    }

    ul {
        border-top: 2px solid rgba(255, 255, 255, 0.2);
        padding-top: 2.8rem;

        @media (--tablet) {
            padding-top: 0;
            border-top: none;
            border-left: 2px solid rgba(255, 255, 255, 0.2);
            padding-left: 4.8rem;
        }
    }

    ul:first-child {
        padding-top: 2.8rem;

        @media (--tablet) {
            padding-top: 0;
            
        }
        @media (--desktop) {
            
        }
    }

    ul + ul {
        @media (--tablet) {
            margin-top: 0;
        }
    }

    ul li {
        padding: 1.4rem 0;
        font-size: var(--p-larger);
        line-height: 2.7rem;
        color: var(--white);
        text-decoration: none;
        cursor: pointer;
        @media (--tablet) {
            font-size: 2.8rem;
            line-height: 3.4rem;
        }

        a {
            color: var(--white);
            text-decoration: none;
        }

        &.main {
            font-size: var(--h3);
            line-height: 3.4rem;

            @media (--tablet) {
                margin-bottom: 2.2rem;
                font-size: var(--h2);
                line-height: 4.2rem;
            }

            &:first-child {
                padding-top: 0;
            }
        }
    }

    hr {
        border-top: 2px solid rgba(255, 255, 255, 0.2);
        margin: 2.8rem 0;

        @media (--tablet) {
            display: none;
        }
    }

    button {
        border: none;
        background: none;
        padding: 0;
        font-size: var(--p-larger);
        line-height: 2.7rem;
        color: var(--white);
        
        font-family: var(--font-family);

        @media (--tablet) {
            font-size: 2.8rem;
            line-height: 3.4rem;
            margin-top: 2.2rem;
        }
    }

    .page-wrapper {
        background: transparent;
        padding-top: 0;
        @media (--tablet) {
            padding-top: 12rem;
            display: flex;
            justify-content: flex-start;
            width: 100%;
        }
    }
    
    
    @media (--tablet) {
        .main-list {
            width: 40%;
        }
        .second-list {
            width: 35%;
        }
        .third-list {
            width: 25%;
        }
    }

    @media screen and (min-width: 1200px) {
        .page-wrapper {
            width: 1100px;
        }
    }
    
    ul .sign-out-container-mobile {
        margin-top: -1rem;
        display: flex;
        @media screen and (min-width: 800px) {
            display: none;
        }
        .btn-container {
            width: 40%;
            display: flex;
        }
        .flags-box-mobile {
            width: 60%;
            padding: 10px 0 10px 15%;
            border-left: 2px solid rgba(255, 255, 255, 0.5);
        }
    }

    .third-list {
        display: none;
        @media screen and (min-width: 800px) {
            display: flex;
            align-items: flex-end;
            padding-bottom: 3rem;
        }
        div {
            width: 100%;

            .sign-out-container-desktop {
                width: 100%;
            }
            .btn-container {
                width: 100%;
                display: flex;
                justify-content: flex-end;
            }
            .flags-box-desktop {
                border-bottom: 2px solid rgba(255, 255, 255, 0.5);
                padding-bottom: 2rem;
            }
        }
    }
}
